<template>
  <main id="campaign-summary-view">
    <div class="campaign-header card__header card__header--with-steps">
      <h4 v-for="(step, index) in steps" :key="index" :class="`card__header-step card__header-step--${step.status}`">
        <span class="step-value">{{ step.value }}</span>
        <span class="step-label">{{ step.label }}</span>
      </h4>
    </div>
    <div class="card" id="main-card">
      <div class="card__content preview-data-container" v-if="Object.keys(campaign) === 0">
        <div class="card__header">
          <h4 class="card__title">{{ $t('loading') }}...</h4>
        </div>
      </div>
      <div class="card__content preview-data-container" v-else>
        <div class="card__header">
          <h4 class="card__title">{{ $t('campaign') }}</h4>
          <!-- <div class="card__header-actions">
            <button class="btn" @click="showCampaignData = !showCampaignData">
              <img src="/img/baseline_keyboard_arrow_up_white_48dp.png" :title="$t('hide')" class="btn__img" v-if="showCampaignData">
              <img src="/img/baseline_keyboard_arrow_down_white_48dp.png" :title="$t('show')" class="btn__img" v-else>
            </button>
          </div> -->
        </div>
        <template v-if="showCampaignData">
          <div class="card__header">
            <fieldset class="form__field form__field--no-label --w50">
              <p class="--hint-text --small-text">{{ $t('account') }}</p>
              <p>{{ accountName }}</p>
            </fieldset>
            <fieldset class="form__field form__field--no-label --w50">
              <p class="--hint-text --small-text">{{ $t('platform') }}</p>
              <p>{{ getPlatformName(campaign.platform) }}</p>
            </fieldset>
          </div>
          <div class="card__header">
            <fieldset class="form__field form__field--no-label --w50">
              <p class="--hint-text --small-text">{{ $t('name') }}</p>
              <p>{{ campaign.name }}</p>
            </fieldset>
            <fieldset class="form__field form__field--no-label --w50">
              <p class="--hint-text --small-text">{{ $t('status') }}</p>
              <p>{{ getStatus(campaign.status) }}</p>
            </fieldset>
          </div>
          <div class="card__header">
            <fieldset class="form__field form__field--no-label --w50">
              <p class="--hint-text --small-text">{{ $t('objective') }}</p>
              <p>{{ $t(campaign.objective) }}</p>
            </fieldset>
            <fieldset class="form__field form__field--no-label --w50">
              <p class="--hint-text --small-text">{{ $t('budget') }}</p>
              <p>{{ campaign.daily_budget !== null ? $t('daily-budget') : $t('lifetime-budget') }}</p>
            </fieldset>
          </div>
          <div class="card__header">
            <fieldset class="form__field form__field--no-label --w50">
              <p class="--hint-text --small-text">{{ $t('amount') }}</p>
              <p>{{ campaign.daily_budget !== null ? campaign.daily_budget : campaign.lifetime_budget }} &euro;</p>
            </fieldset>
            <fieldset class="form__field form__field--no-label --w50">
              <p class="--hint-text --small-text">{{ $t('bid-strategy') }}</p>
              <p>{{ campaign.bid_strategy ? $t(`bid-strategy-${campaign.bid_strategy}`) : '' }}</p>
            </fieldset>
          </div>
          <div class="card__actions">
            <div class="card__actions--left">&nbsp;</div>
            <div class="card__actions--right">
              <button type="button" class="btn btn--new-secondary" @click="createAdSet">{{ $t('create-ad-set') }}</button>
            </div>
          </div>
        </template>
      </div>
      <div class="card__content preview-data-container" v-if="adsets.length === 0">
        <div class="card__header">
          <h4 class="card__title">{{ $t('loading') }}...</h4>
        </div>
      </div>
      <template v-for="(adset, adsetIndex) in adsets" v-else>
        <div class="card__content preview-data-container" :key="`adset-${adsetIndex}`">
          <div class="card__header">
            <h4 class="card__title">{{ adset.name }}</h4>
            <!-- <div class="card__header-actions">
              <button class="btn" @click="toggleAdsetVisibility(adsetIndex)">
                <img src="/img/baseline_keyboard_arrow_up_white_48dp.png" :title="$t('hide')" class="btn__img" v-if="isAdsetVisible(adsetIndex) >= 0">
                <img src="/img/baseline_keyboard_arrow_down_white_48dp.png" :title="$t('show')" class="btn__img" v-else>
              </button>
            </div> -->
          </div>
          <div class="card__header" :key="`adset-${adsetIndex}-data`">
            <fieldset class="form__field form__field--no-label --w25">
              <p class="--hint-text --small-text">{{ $t('platform') }}</p>
              <p>{{ getPlatformName(adset.platform) }}</p>
            </fieldset>
            <fieldset class="form__field form__field--no-label --w20">
              <p class="--hint-text --small-text">{{ $t('start-date') }}</p>
              <p>{{ getFormattedDate(adset.start_date) }}</p>
            </fieldset>
            <fieldset class="form__field form__field--no-label --w20">
              <p class="--hint-text --small-text">{{ $t('end-date') }}</p>
              <p>{{ getFormattedDate(adset.end_date) }}</p>
            </fieldset>
            <fieldset class="form__field form__field--no-label --w25">
              <p class="--hint-text --small-text">{{ $t('status') }}</p>
              <p>{{ getStatus(adset.status) }}</p>
            </fieldset>
            <fieldset class="form__field form__field--no-label --w10">
              <button class="btn" @click="toggleAdsetVisibility(adsetIndex)" v-if="adset.ads && adset.ads.length > 0">
                <img src="/img/baseline_keyboard_arrow_up_white_48dp.png" :title="$t('hide')" class="btn__img" v-if="isAdsetVisible(adsetIndex) >= 0">
                <img src="/img/baseline_keyboard_arrow_down_white_48dp.png" :title="$t('show')" class="btn__img" v-else>
              </button>
            </fieldset>
          </div>
          <template v-if="isAdsetVisible(adsetIndex) >= 0">
            <template v-for="(ad, adIndex) in adset.ads">
              <div class="card__header card__header--separator" :key="`adset-${adsetIndex}-ad-${adIndex}`">
                <fieldset class="form__field form__field--no-label --w55">
                  <p class="--hint-text --small-text">{{ $t('ad') }}</p>
                  <p>{{ ad.name }}</p>
                </fieldset>
                <fieldset class="form__field form__field--no-label --w20">
                  <p class="--hint-text --small-text">{{ $t('objective') }}</p>
                  <p>{{ $t(ad.objective) }}</p>
                </fieldset>
                <fieldset class="form__field form__field--no-label --w25">
                  <p class="--hint-text --small-text">{{ $t('status') }}</p>
                  <p>{{ getStatus(ad.status) }}</p>
                </fieldset>
              </div>
              <div class="card__header" :key="`adset-${adsetIndex}-ad-${adIndex}-creative`">
                <fieldset class="form__field form__field--no-label --w25">
                  <p class="--hint-text --small-text">{{ $t('creative') }}</p>
                  <p>{{ ad.creative.name }}</p>
                </fieldset>
                <fieldset class="form__field form__field--no-label --w20">
                  <p class="--hint-text --small-text">{{ $t('link') }}</p>
                  <p>{{ ad.creative.link }}</p>
                </fieldset>
                <fieldset class="form__field form__field--no-label --w65">
                  <p class="--hint-text --small-text">{{ $t('text') }}</p>
                  <p>{{ ad.creative.text }}</p>
                </fieldset>
              </div>
            </template>
          </template>
          <div class="card__actions" :key="`adset-${adsetIndex}-actions`">
            <div class="card__actions--left">&nbsp;</div>
            <div class="card__actions--right">
              <button type="button" class="btn btn--new-secondary" @click="createAd(adset._id)">{{ $t('create-ad') }}</button>
            </div>
          </div>
        </div>
      </template>
      <div class="modal__actions">
        <div class="modal__actions--left">
          <!-- <button type="button" class="btn btn--text" @click="onCancel">{{ $t('cancel') }}</button> -->
        </div>
        <div class="modal__actions--right">
          <button type="button" class="btn btn--new" @click="goToDashboard">{{ $t('dashboard') }}</button>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import {
  SHOW_NOTIFICATION,
  GET_CAMPAIGNS,
  GET_ACCOUNTS,
  GET_PLATFORMS,
  GET_ADSETS,
  GET_ADS,
  GET_AD_CREATIVE,
  GET_STATUSES
} from '@/core/action-types'
import {
  mapGetters,
  mapActions
} from 'vuex'
import i18n from '@/plugins/i18n'
export default {
  name: 'campaign-adset-ad-summary',
  components: {
  },
  data: function () {
    return {
      locale: i18n.locale,
      steps: [
        { value: '1', label: this.$t('campaign'), status: 'completed' },
        { value: '2', label: this.$t('ad-sets'), status: 'completed' },
        { value: '3', label: this.$t('ads'), status: 'completed' },
        { value: '4', label: this.$t('summary'), status: 'active' }
      ],
      campaign: {},
      adsets: [],
      showCampaignData: true,
      showAdSetsData: []
    }
  },
  computed: {
    ...mapGetters([
      'accounts',
      'platforms',
      'statuses',
      'pages'
    ]),
    accountName () {
      return this.accounts.length > 0 && this.campaign.account_id ? this.accounts.find(item => item.api_id === this.campaign.account_id).name : ''
    }
  },
  mounted () {
    this.fetchAccounts()
    this.getPlatforms()
    let query = { directSearch: true, campaign_ids: [this.$route.params.id] }
    const onComplete = function ({ data }) {
      this.campaign = data[0]
      // const accountId = this.campaign.account_id
      this.steps[0].label = this.campaign.name
      // this.getFacebookPixels({ id: this.campaign.account_id })
      let query = {
        directSearch: true,
        campaign_ids: [this.campaign._id]
      }
      const onComplete = function ({ data }) {
        this.adsets = data
        // TODO: cambiar la forma de cargar los ads:
        // Hacer un bucle por cada adset, para poder identificar a qué adset pertenece cada ad
        this.adsets.forEach(adset => {
          let query = {
            directSearch: true,
            campaign_ids: [this.campaign._id],
            adset_ids: [adset._id]
          }
          const onComplete = function ({ data }) {
            this.$set(adset, 'ads', data)
          }
          query.onComplete = onComplete.bind(this)
          this.getAds(query)
        })
      }
      query.onComplete = onComplete.bind(this)
      this.getAdsets(query)
    }
    query.onComplete = onComplete.bind(this)
    this.getCampaigns(query)
  },
  methods: {
    ...mapActions({
      showNotification: SHOW_NOTIFICATION,
      getAccounts: GET_ACCOUNTS,
      getPlatforms: GET_PLATFORMS,
      getCampaigns: GET_CAMPAIGNS,
      getStatuses: GET_STATUSES,
      getAdsets: GET_ADSETS,
      getAds: GET_ADS,
      getAdCreative: GET_AD_CREATIVE
    }),
    fetchAccounts () {
      let query = { platform: this.platformId }
      if (this.clientId !== undefined) {
        query.team_id = this.clientId
      }
      this.getAccounts(query)
    },
    getFormattedDate (date) {
      return date && date !== '' ? new Date(date).toLocaleString(this.locale, { year: 'numeric', month: 'numeric', day: 'numeric' }) : ''
    },
    getPlatformName (platformId) {
      return this.platforms.length > 0 && this.campaign.platform ? this.platforms.find(item => item.value === platformId).label : ''
    },
    getStatus (statusId) {
      return statusId ? this.fauxTranslateString(statusId) : ''
    },
    toggleAdsetVisibility (adsetIndex) {
      let index = this.isAdsetVisible(adsetIndex)
      if (index >= 0) {
        this.showAdSetsData.splice(index, 1)
      } else {
        this.showAdSetsData.push(adsetIndex)
      }
    },
    isAdsetVisible (adsetIndex) {
      return this.showAdSetsData.indexOf(adsetIndex)
    },
    capitalizeFirstLetter (string) {
      return `${string.charAt(0).toUpperCase()}${string.slice(1)}`
    },
    fauxTranslateString (string) {
      return this.capitalizeFirstLetter(string.toLowerCase().replaceAll('_', ' '))
    },
    createAdSet () {
      this.$router.push({ path: `/campaign/${this.campaign._id}/adset`, query: this.$route.query })
    },
    createAd (adsetId) {
      this.$router.push({ path: `/campaign/${this.campaign._id}/adset/${adsetId}/ad`, query: this.$route.query })
    },
    goToDashboard () {
      this.$router.push({ name: 'dashboard', params: { tab: 'campaigns' }, query: this.$route.query })
    }
    // EVENTS:
    // ...
  }
}
</script>
<i18n src="@/core/i18n-common.json"></i18n>
<style scoped>
/* TODO: INTEGRAR ESTILOS CON LOS ESTILOS GENERALES */
#campaign-summary-view {
  margin: 0;
  padding: 0 0 100px;
  display: flex;
  flex-direction: column;
}
.campaign-header {
  margin: 20px auto 0;
  width: calc(100% - 40px);
  max-width: 1200px;
}
/* .preview-wrapper {
  display: grid;
  grid-template-columns: 1fr 400px;
  grid-column-gap: 20px;
} */
#main-card {
  display: block;
  margin: 20px auto 0;
  width: calc(100% - 40px);
  max-width: 1200px;
}
.preview-data-container {
  padding: 20px 30px;
}
/* #preview-card {
  margin-top: 20px;
  width: calc(100% - 40px);
  max-width: 400px;
  height: 400px;
  position: sticky;
  top: 20px;
  z-index: 1;
}
.preview-container {
  flex-wrap: wrap;
} */
.card__content + .card__content {
  margin-top: 20px;
}
.card__header-step {
  background-color: var(--app-background-color);
}
.campaign-header {
  display: flex;
  line-height: 36px;
  justify-content: space-between;
  padding: 10px 0;
}
.card__header--separator {
  border-top: 2px solid var(--app-lines-color);
  /* border-top: none; */
}
.card__header:first-of-type + .card__header--separator {
  /* border-top: 2px solid var(--app-lines-color); */
  border-top: none !important;
}
.pills-wrapper {
  justify-content: space-around;
  align-items: inherit;
  padding-bottom: 40px;
}
.pill {
  background-color: var(--app-light-blue);
  border-radius: 20px;
  text-align: center;
  flex-basis: 200px;
  padding: 0 7px 7px !important;
}
.pill label {
  margin-top: 100px;
  left: 0;
  text-align: left;
  display: block;
  position: relative;
}
.pill label.--ta-c {
  text-align: center;
}
.pill label::before {
  background-position-y: 4px !important;
}
.pill-img {
  filter: var(--app-png-dark-grey-filter);
  position: absolute;
  top: -96px;
  left: calc(100% - 134px);
  height: 96px;
  width: 96px;
}
.pill-img-blue {
  filter: var(--app-png-dark-blue-filter);
}
.pill-img-red {
  filter: var(--app-png-dark-red-filter);
}
.pill-img-green {
  filter: var(--app-png-medium-green-filter);
}
.pill-img-ochre {
  filter: var(--app-png-ochre-filter);
}
.pill label[disabled] {
  color: var(--app-disabled-color);
}
.pill label[disabled] .pill-img,
.pill label[disabled]::before {
  filter: var(--app-png-disabled-filter);
}
/* .preview-files-wrapper {
  display: flex;
  padding-bottom: 40px;
}
.preview-file {
  flex-basis: 100%;
  padding: 0 10px 10px;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-template-rows: 1fr 30px;
  grid-template-areas:
    "thumbnail 1st-row"
    "thumbnail 2nd-row";
  gap: 10px;
}
.preview-file + .preview-file {
  border-bottom: 1px solid var(--app-lines-color);
  margin-bottom: 10px;
}
.preview-file-thumbnail {
  width: 200px;
  grid-area: thumbnail;
}
.preview-file-clear-button {
  width: 100px;
} */
/* .wizard-form {
  margin-top: 20px;
} */
.form__field {
  position: relative;
  padding: 0 0 7px;
  margin: 20px 0 0 0;
  border: none;
}
.form__field--only-text {
  padding: 0 20px 7px;
}
.form__field--no-label {
  margin-top: 0;
}
.input__label--left {
  left: 0;
}
.input {
  border-width: 0;
  /* background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2); */
  /* background-size: 0 2px, 100% 1px; */
  /* background-repeat: no-repeat; */
  /* background-position: center bottom, center calc(100% - 1px); */
  /* background-color: transparent; */
  box-shadow: none;
  /* border-radius: 0; */
  color: var(--app-font-color);
  float: none;
  font-size: var(--app-base-fontsize);
  font-weight: var(--app-font-weight);
  height: 36px;
  line-height: var(--app-line-height);
  margin: 0 0 7px;
  padding: 7px 0;
  /* transition: background 0s ease-out; */
  width: 100%;
  outline: none;
}
.input:disabled {
  color: var(--app-disabled-color);
  cursor: default;
  /* background-color: rgba(235, 235, 228, 0.3); */
}
.input:-internal-autofill-selected {
  /* appearance: menulist-button; */
  /* background-color: #e8f0fe !important; */
  background-color: var(--app-light-blue) !important;
  /* background-image: none !important; */
  /* color: -internal-light-dark(black, white) !important; */
}
.input__text {
  padding: 7px 20px;
}
.input__date {
  padding: 7px 0px 7px 20px;
}
/* .input__date {
  background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2), url('/img/baseline_today_black_48dp.png');
  background-size: 0 2px, 100% 1px, 24px 24px;
  background-repeat: no-repeat;
  background-position: center bottom, center calc(100% - 1px), calc(100% - 3px) 3px;
  background-color: transparent;
}
.input__date--from {
  background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2), url('/img/baseline_today_black_48dp.png');
}
.input__date--to {
  background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2), url('/img/baseline_event_black_48dp.png');
}
.input__text:focus {
  outline: none;
  background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2);
  background-size: 100% 2px, 100% 1px;
  box-shadow: none;
  transition-duration: 0.3s;
} */
.input__label {
  color: var(--app-disabled-color);
  cursor: text;
  font-size: var(--app-base-fontsize);
  font-weight: var(--app-font-weight);
  line-height: var(--input-line-height);
  margin: 10px 0 0;
  position: absolute;
  pointer-events: none;
  transition: 0.3s ease all;
  top: -7px;
  left: 20px;
  will-change: left, top, contents;
}
.input__date ~ .input__label,
.input__text:focus ~ .input__label,
.input__text:valid:not(.input--empty) ~ .input__label,
.input__text:-webkit-autofill ~ .input__label,
.input__text:read-only ~ .input__label,
.input__text:disabled ~ .input__label,
.input__label--focus,
.input__label__multivalue,
.input__label--invalid,
.input__text--invalid,
.input__text:invalid,
.input__text:invalid:not(:-webkit-autofill) ~ .input__label {
  top: -30px;
  left: 20px;
  font-size: 11px;
  line-height: 1;
}
.input__label--invalid,
.input__text--invalid,
.input__date__label--invalid,
.input__text:invalid,
.input__text:invalid:not(:-webkit-autofill) ~ .input__label {
  color: var(--app-dark-red);
}
/* .input__label__multivalue {
  margin: 10px 0 0;
  position: relative;
  display: block;
  top: unset;
  font-size: 11px;
  line-height: 1;
} */
.input__label__singlevalue {
  /* margin: 0 10px 10px 0; */
  margin: 5px 10px 10px 2px;
  border: none;
  border-radius: 4px;
  /* background-color: var(--card-background-color);
  background-image: linear-gradient(black, black), url('/img/baseline_radio_button_unchecked_white_48dp.png');
  background-repeat: no-repeat;
  background-position-x: 3px;
  background-position-y: 3px;
  background-size: 25px;
  background-blend-mode: difference; */
  line-height: var(--input-line-height);
  padding: 0px 10px 0px 25px;
  position: relative;
  display: inline-block;
  top: unset;
  pointer-events: unset;
  cursor: pointer;
  color: black;
}
/* .input__label__singlevalue.input__label--checkbox {
  background-image: linear-gradient(black, black), url('/img/baseline_check_box_outline_blank_white_48dp.png');
} */
.input__radio--checked + .input__label__singlevalue.input__label--radio,
.input__checkbox--checked + .input__label__singlevalue.input__label--checkbox {
  /* background-image: linear-gradient(black, black), url('/img/baseline_check_box_white_48dp.png'); */
  color: var(--app-dark-blue);
}
.input__label__singlevalue:hover {
    outline: none;
    box-shadow: var(--app-shadow-color);
}
.input__radio {
  display: none;
}
/* .input__radio--checked + .input__label__singlevalue,
.input__radio[checked] + .input__label__singlevalue {
    background-image: linear-gradient(black, black), url('/img/baseline_radio_button_checked_white_48dp.png');
} */
.input__radio--disabled + .input__label__singlevalue:hover {
    box-shadow: none;
    cursor: default;
}
.input__select--multiple {
  height: 60px;
  padding: 0;
}
.input__textarea,
.input__select--multiple--large {
  height: 120px;
  margin-top: 5px;
  border-radius: 10px;
  overflow: auto;
  text-indent: 0;
  background-image: none;
}
.tags-list {
  margin-top: 5px;
}
.tags-list--w100 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  height: 125px;
  overflow: auto;
  max-width: 700px;
  width: 700px;
  box-sizing: border-box;
}
.tag {
  max-width: calc(100% - 40px);
  position: relative;
  padding-right: 40px;
}
.tag .btn__img {
  position: absolute;
  right: 10px;
  top: calc(50% - 12px);
  margin: 0;
  padding: 0;
}
.input__fake {
  opacity: 0;
  width: 0;
  height: 0;
}
option {
  padding: 5px 20px;
}
option:checked {
  background-color: var(--app-dark-blue) !important;
  color: var(--app-white) !important;
}
</style>
