<template>
  <main id="campaign-adset-ad-view">
    <div class="campaign-header card__header card__header--with-steps">
      <h4 v-for="(step, index) in steps" :key="index" :class="`card__header-step card__header-step--${step.status}`">
        <span class="step-value">{{ step.value }}</span>
        <span class="step-label">{{ step.label }}</span>
      </h4>
    </div>
    <div class="card" id="main-card">
      <form action="#create" class="wizard-form" @submit="onSubmit" ref="create-form">
      <div class="card__content">
        <div class="card__header">
          <h4 class="card__title">{{ $t('ad-name') }}</h4>
        </div>
        <div class="card__header">
          <fieldset class="form__field --w75">
            <input type="text" class="input input__text" required name="name" id="name" v-model="name" autocomplete="new-password">
          </fieldset>
          <fieldset class="form__field --w25">
            <label for="statusId" class="input__label input__label__multivalue">{{ $t('status') }} *</label>
            <select class="input input__select" required name="statusId" id="statusId" v-model="statusId">
              <option
                v-for="(dataItem, index) in availableStatuses"
                :value="dataItem.value"
                :selected="'PAUSED' === dataItem.value"
                :key="index">{{ dataItem.label }}</option>
            </select>
          </fieldset>
        </div>
      </div>
      <div class="card__content">
        <div class="card__header">
          <h4 class="card__title">{{ $t('identity') }}</h4>
        </div>
        <div class="card__header">
          <p>{{ $t('identity-text') }}.</p>
        </div>
        <div class="card__header">
          <fieldset class="form__field --w50">
            <label for="facebookPageId" class="input__label input__label__multivalue">{{ $t('facebook-page') }} *</label>
            <select class="input input__select" required name="facebookPageId" id="facebookPageId" v-model="facebookPageId">
              <option value="" disabled selected>{{ $t('please-choose') }}</option>
              <option
                v-for="(dataItem, index) in availableFacebookPages"
                :value="dataItem.value"
                :key="index">{{ dataItem.label }}</option>
            </select>
          </fieldset>
          <fieldset class="form__field --w50" v-if="facebookPageId">
            <label for="instagramPageId" class="input__label input__label__multivalue">{{ $t('instagram-page') }}</label>
            <select class="input input__select" name="instagramPageId" id="instagramPageId" v-model="instagramPageId">
              <option value="" disabled selected>{{ $t('please-choose') }}</option>
              <option
                v-for="(dataItem, index) in availableInstagramPages"
                :value="dataItem.value"
                :key="index">{{ dataItem.label }}</option>
            </select>
          </fieldset>
        </div>
      </div>
      <div class="card__content">
        <div class="card__header">
          <h4 class="card__title">{{ $t('format') }}</h4>
        </div>
        <div class="card__header pills-wrapper">
          <fieldset class="form__field form__field--no-label --w25 pill">
            <input
              class="input__radio"
              :class="[ format === 'single_image' ? 'input__radio--checked' : '' ]"
              type="radio"
              v-model="format"
              value="single_image"
              id="formatSingleImage"
              name="format" />
            <label
              class="input__label input__label--radio input__label__singlevalue"
              for="formatSingleImage">
              <img src="/img/baseline_image_white_48dp.png" class="pill-img pill-img-blue">
              {{ $t('single-image-or-video') }}
            </label>
          </fieldset>
          <fieldset class="form__field form__field--no-label --w25 pill">
            <input disabled
              class="input__radio"
              :class="[ format === 'carousel_images_single_item' ? 'input__radio--checked' : '' ]"
              type="radio"
              v-model="format"
              value="carousel_images_single_item"
              id="formatCarouselImagesSingleItem"
              name="format" />
            <label disabled
              class="input__label input__label--radio input__label__singlevalue"
              for="formatCarouselImagesSingleItem">
              <img src="/img/baseline_image_white_48dp.png" class="pill-img pill-img-green">
              {{ $t('carousel-images-single-item') }}
            </label>
          </fieldset>
          <fieldset class="form__field form__field--no-label --w25 pill">
            <input disabled
              class="input__radio"
              :class="[ format === 'carousel_slideshows' ? 'input__radio--checked' : '' ]"
              type="radio"
              v-model="format"
              value="carousel_slideshows"
              id="formatCarouselSlideshows"
              name="format" />
            <label disabled
              class="input__label input__label--radio input__label__singlevalue"
              for="formatCarouselSlideshows">
              <img src="/img/baseline_view_carousel_white_48dp.png" class="pill-img pill-img-ochre">
              {{ $t('carousel-slideshows') }}
            </label>
          </fieldset>
          <fieldset class="form__field form__field--no-label --w25 pill">
            <input disabled
              class="input__radio"
              :class="[ format === 'carousel_images_multi_items' ? 'input__radio--checked' : '' ]"
              type="radio"
              v-model="format"
              value="carousel_images_multi_items"
              id="formatCarouselImagesMultiItems"
              name="format" />
            <label disabled
              class="input__label input__label--radio input__label__singlevalue"
              for="formatCarouselImagesMultiItems">
              <img src="/img/baseline_view_carousel_white_48dp.png" class="pill-img pill-img-red">
              {{ $t('carousel-images-multi-items') }}
            </label>
          </fieldset>
        </div>
      </div>
      <div class="card__content">
        <div class="card__header">
          <h4 class="card__title">{{ $t('media') }}</h4>
        </div>
        <div class="card__header">
          <p>
            {{ $t('media-text.1') }}
            <a target="_blank" class="btn btn--text btn--text-inline" href="https://www.facebook.com/business/ads-guide/image">{{ $t('media-text.2') }}</a>
            {{ $t('media-text.3') }}
            <a target="_blank" class="btn btn--text btn--text-inline" href="https://www.facebook.com/business/ads-guide/video">{{ $t('media-text.4') }}</a>.
          </p>
        </div>
        <div class="card__header pills-wrapper">
          <span class="--w25"></span>
          <fieldset class="form__field form__field--no-label --w25 pill" v-if="!imageFile">
            <input type="file" class="input__file" id="image" name="image" accept="image/*" @change="processImageFile($event)">
            <label class="--ta-c --pointer" for="image">
              <img src="/img/baseline_image_white_48dp.png" class="pill-img pill-img-green">
              {{ $t('add-image') }}
            </label>
          </fieldset>
          <fieldset class="form__field form__field--no-label --w25 pill" v-if="!videoFile">
            <input type="file" class="input__file" id="video" name="video" accept="video/*" @change="processVideoFile($event)">
            <label class="--ta-c --pointer" for="video">
              <img src="/img/baseline_add_to_queue_white_48dp.png" class="pill-img pill-img-ochre">
              {{ $t('add-video') }}
            </label>
          </fieldset>
          <span class="--w25"></span>
        </div>
        <div class="card__header preview-files-wrapper" v-if="imageFile">
          <div class="preview-file">
            <img class="preview-file-thumbnail" :src="getFileBlob(imageFile)" id="image-preview" ref="image-preview" @load="calcImageDimensions" />
            <p v-if="validImageFileType(imageFile)">
              {{ `${$t('file-name')}: ${imageFile.name}` }}<br>
              <span class="--hint-text">{{ imageDimensions }}</span><br>
              <span class="--hint-text">{{ `${$t('file-size')} ${returnFileSize(imageFile.size)}` }}</span>
            </p>
            <p v-else>{{ `${$t('file-name')} ${imageFile.name}: ${$t('file-invalid')}.` }}</p>
            <button type="button" class="btn btn--text preview-file-clear-button" @click="imageFile = undefined">{{ $t('clear') }}</button>
          </div>
        </div>
        <div class="card__header preview-files-wrapper" v-if="videoFile">
          <div class="preview-file" :class="[ videoStatus.length > 0 ? 'preview-file--is-processing' : '']">
            <video class="preview-file-thumbnail" controls>
              <source :src="getFileBlob(videoFile)" id="video-preview" ref="video-preview">
              Your browser does not support HTML5 video.
            </video>
            <p v-if="validVideoFileType(videoFile)">
              {{ `${$t('file-name')}: ${videoFile.name}` }}<br>
              <span class="--hint-text">{{ videoDimensions }}</span><br>
              <span class="--hint-text">{{ `${$t('file-size')} ${returnFileSize(videoFile.size)}` }}</span>
            </p>
            <p v-else>{{ `${$t('file-name')} ${videoFile.name}: ${$t('file-invalid')}.` }}</p>
            <button type="button" class="btn btn--text preview-file-clear-button" @click="videoFile = undefined">{{ $t('clear') }}</button>
          </div>
        </div>
      </div>
      <div class="preview-wrapper">
        <div class="card__content">
          <div class="card__header">
            <h4 class="card__title">{{ $t('text-and-link') }}</h4>
          </div>
          <div class="card__header">
            <fieldset class="form__field --w100">
              <textarea id="text" name="text" class="input input__text input__textarea" v-model="text" required :placeholder="$t('ad-text-placeholder')"></textarea>
              <label for="text" class="input__label">{{ $t('text') }} *</label>
            </fieldset>
          </div>
          <div class="card__header">
            <fieldset class="form__field --w100">
              <input type="text" class="input input__text" name="title" id="title" v-model="title" required autocomplete="new-password" :placeholder="$t('ad-title-placeholder')">
              <label for="title" class="input__label">{{ $t('title') }} *</label>
            </fieldset>
          </div>
          <div class="card__header">
            <fieldset class="form__field --w100">
              <textarea id="description" name="description" class="input input__text input__textarea" v-model="description" required :placeholder="$t('ad-description-placeholder')"></textarea>
              <label for="description" class="input__label">{{ $t('description') }} *</label>
            </fieldset>
          </div>
          <div class="card__header">
            <fieldset class="form__field --w75">
              <input type="text" class="input input__text" name="link" id="link" v-model="link" required autocomplete="new-password" :placeholder="$t('ad-link-placeholder')">
              <label for="link" class="input__label">{{ $t('website-url') }} *</label>
            </fieldset>
            <fieldset class="form__field --w25">
              <button type="button" class="btn btn--text" @click="showCreateUrlParameters = !showCreateUrlParameters">{{ showCreateUrlParameters ? $t('hide-url-paramenters') : $t('create-url-paramenters') }}</button>
            </fieldset>
          </div>
          <div class="card__header" v-if="showCreateUrlParameters">
            <fieldset class="form__field --w25">
              <input type="text" class="input input__text" name="utmSource" id="utmSource" v-model="utmSource" autocomplete="new-password">
              <label for="utmSource" class="input__label">{{ $t('utm-source') }}</label>
            </fieldset>
            <fieldset class="form__field --w25">
              <input type="text" class="input input__text" name="utmMedium" id="utmMedium" v-model="utmMedium" autocomplete="new-password">
              <label for="utmMedium" class="input__label">{{ $t('utm-medium') }}</label>
            </fieldset>
            <fieldset class="form__field --w25">
              <input type="text" class="input input__text" name="utmCampaign" id="utmCampaign" v-model="utmCampaign" autocomplete="new-password">
              <label for="utmCampaign" class="input__label">{{ $t('utm-campaign') }}</label>
            </fieldset>
            <fieldset class="form__field --w25">
              <input type="text" class="input input__text" name="utmContent" id="utmContent" v-model="utmContent" autocomplete="new-password">
              <label for="utmContent" class="input__label">{{ $t('utm-content') }}</label>
            </fieldset>
          </div>
          <div class="card__header">
            <fieldset class="form__field --w100">
              <input type="text" class="input input__text" name="caption" id="caption" v-model="caption" autocomplete="new-password" :placeholder="$t('ad-caption-placeholder')">
              <label for="caption" class="input__label">{{ $t('caption') }}</label>
            </fieldset>
          </div>
          <div class="card__header">
            <fieldset class="form__field --w50">
              <label for="callToActionId" class="input__label input__label__multivalue">{{ $t('call-to-action') }} *</label>
              <select class="input input__select" required name="callToActionId" id="callToActionId" v-model="callToActionId">
                <option value="" disabled selected>{{ $t('please-choose') }}</option>
                <option
                  v-for="(dataItem, index) in filteredCallToActionValues"
                  :value="dataItem.value"
                  :key="index">{{ dataItem.label }}</option>
              </select>
            </fieldset>
          </div>
        </div>
        <div class="card__content" v-if="facebookPixelDataList.length > 0">
          <div class="card__header">
            <h4 class="card__title">{{ $t('conversion-tracking') }}</h4>
          </div>
          <div class="card__header">
            <fieldset class="form__field --w100">
              <label for="facebookPixel" class="input__label input__label__multivalue">{{ this.$t('pixel-facebook') }}</label>
              <select class="input input__select" id="facebookPixel" name="facebookPixel" v-model="facebookPixel">
                <option value="" disabled selected>{{ $t('please-choose') }}</option>
                <option
                  v-for="(dataItem, index) in facebookPixelDataList"
                  :value="dataItem.value"
                  :key="index">{{ dataItem.label }}</option>
              </select>
            </fieldset>
          </div>
          <div class="card__header">
            <fieldset class="form__field --w100">
              <label for="facebookPixelEvent" class="input__label input__label__multivalue">{{ this.$t('event') }}</label>
              <select class="input input__select" id="facebookPixelEvent" name="facebookPixelEvent" v-model="facebookPixelEvent">
                <option value="" disabled selected>{{ $t('please-choose') }}</option>
                <option
                  v-for="(dataItem, index) in facebookPixelEventDataList"
                  :value="dataItem.value"
                  :key="index">{{ dataItem.label }}</option>
              </select>
            </fieldset>
          </div>
          <!-- array de combinaciones, cada elemento del array es un array con dos valores del json -->
          <div class="card__header">
            <fieldset class="form__field --w100">
              <label for="facebookConversionWindow" class="input__label input__label__multivalue">{{ this.$t('conversion-window') }}</label>
              <select class="input input__select"
                id="facebookConversionWindow" name="facebookConversionWindow" v-model="facebookConversionWindow">
                <option value="" disabled selected>{{ $t('please-choose') }}</option>
                <option
                  v-for="(dataItem, index) in facebookConversionWindowCombinedDataList"
                  :value="dataItem.value"
                  :key="index">{{ dataItem.label }}</option>
              </select>
            </fieldset>
          </div>
        </div>
        <div class="ad-preview">
          <div class="card__content">
            <div class="card__header">
              <p v-if="!adPreviewData">{{ $t('creative-preview-text') }}</p>
            </div>
            <div class="card__header">
              <fieldset class="form__field --w100">
                <label for="adFormat" class="input__label input__label__multivalue">{{ $t('creative-format') }}</label>
                <select class="input input__select" name="adFormat" id="adFormat" v-model="adFormat">
                  <option value="" disabled selected>{{ $t('please-choose') }}</option>
                  <option
                    v-for="(dataItem, index) in availableCreativeFormatsValues"
                    :value="dataItem.value"
                    :key="index">{{ dataItem.label }}</option>
                </select>
              </fieldset>
            </div>
            <div class="card__header">
              <button type="button" class="btn btn--new" @click="onPreviewSubmit" :disabled="!adFormat || videoStatus.length > 0">{{ $t('preview') }}</button>
            </div>
            <div class="card__header"
              v-if="adFormat !== '' && adPreviewData !== undefined && adPreviewData !== null && adPreviewData.previews !== undefined"
              v-html="adPreviewData.previews[0].body">
            </div>
          </div>
        </div>
      </div>
      <div class="modal__actions">
        <div class="modal__actions--left">
          <button type="button" class="btn btn--text" @click="onCancel">{{ $t('cancel') }}</button>
        </div>
        <div class="modal__actions--right">
          <button type="button" class="btn btn--new" @click="onSubmit" :disabled="videoStatus.length > 0">{{ $t('save') }}</button>
        </div>
      </div>
      </form>
    </div>
  </main>
</template>
<script>
import {
  SHOW_NOTIFICATION,
  GET_CAMPAIGNS,
  GET_ADSETS,
  GET_STATUSES,
  GET_CREATIVE_FORMATS,
  GET_TARGETING_FACEBOOK,
  GET_FACEBOOK_PIXELS,
  GET_FACEBOOK_CUSTOM_EVENTS,
  GET_PAGES_FACEBOOK,
  GET_PAGES_INSTAGRAM,
  // GET_CALL_TO_ACTION_VALUES,
  SAVE_NEW_CREATIVE,
  UPLOAD_VIDEO,
  GET_VIDEO_STATUS,
  FINISH_VIDEO_STATUS_PROCESSING,
  GET_FB_CALL_TO_ACTION
} from '@/core/action-types'
import {
  mapGetters,
  mapActions
} from 'vuex'
import i18n from '@/plugins/i18n'
export default {
  name: 'campaign-adset-ad',
  components: {
  },
  data: function () {
    return {
      locale: i18n.locale,
      steps: [
        { value: '1', label: this.$t('campaign'), status: 'completed' },
        { value: '2', label: this.$t('ad-sets'), status: 'completed' },
        { value: '3', label: this.$t('ads'), status: 'active' },
        { value: '4', label: this.$t('summary'), status: 'inactive' }
      ],
      campaign: {},
      adset: {},
      platformId: '',
      name: '',
      statusId: 'PAUSED',
      facebookPageId: undefined,
      instagramPageId: undefined,
      format: 'single_image',
      imageFile: undefined,
      imageDimensions: '',
      videoFile: undefined,
      videoDimensions: '',
      text: '',
      title: '',
      description: '',
      link: '',
      caption: '',
      callToActionId: '',
      showCreateUrlParameters: false,
      utmSource: '',
      utmMedium: '',
      utmCampaign: '',
      utmContent: '',
      facebookPixel: '',
      facebookPixelDataList: [],
      facebookPixelEvent: '',
      facebookPixelEventDataList: [
        { value: 'ADD_PAYMENT_INFO', label: this.$t('ADD_PAYMENT_INFO') },
        { value: 'ADD_TO_CART', label: this.$t('ADD_TO_CART') },
        { value: 'ADD_TO_WISHLIST', label: this.$t('ADD_TO_WISHLIST') },
        { value: 'COMPLETE_REGISTRATION', label: this.$t('COMPLETE_REGISTRATION') },
        { value: 'CONTACT', label: this.$t('CONTACT') },
        { value: 'CONTENT_VIEW', label: this.$t('CONTENT_VIEW') },
        { value: 'CUSTOMIZE_PRODUCT', label: this.$t('CUSTOMIZE_PRODUCT') },
        { value: 'DONATE', label: this.$t('DONATE') },
        { value: 'FACEBOOK_SELECTED', label: this.$t('FACEBOOK_SELECTED') },
        { value: 'FIND_LOCATION', label: this.$t('FIND_LOCATION') },
        { value: 'INITIATED_CHECKOUT', label: this.$t('INITIATED_CHECKOUT') },
        { value: 'LEAD', label: this.$t('LEAD') },
        { value: 'LISTING_INTERACTION', label: this.$t('LISTING_INTERACTION') },
        { value: 'OTHER', label: this.$t('OTHER') },
        { value: 'PURCHASE', label: this.$t('PURCHASE') },
        { value: 'SCHEDULE', label: this.$t('SCHEDULE') },
        { value: 'SEARCH', label: this.$t('SEARCH') },
        { value: 'START_TRIAL', label: this.$t('START_TRIAL') },
        { value: 'SUBMIT_APPLICATION', label: this.$t('SUBMIT_APPLICATION') },
        { value: 'SUBSCRIBE', label: this.$t('SUBSCRIBE') }
      ],
      facebookConversionWindow: null,
      facebookConversionWindowDataList: [
        [
          { event_type: 'CLICK_THROUGH', window_days: '7' },
          { event_type: 'VIEW_THROUGH', window_days: '1' }
        ],
        [
          { event_type: 'CLICK_THROUGH', window_days: '1' },
          { event_type: 'VIEW_THROUGH', window_days: '7' }
        ],
        [
          { event_type: 'CLICK_THROUGH', window_days: '1' },
          { event_type: 'VIEW_THROUGH', window_days: '1' }
        ],
        [
          { event_type: 'CLICK_THROUGH', window_days: '7' },
          { event_type: 'VIEW_THROUGH', window_days: '7' }
        ]
      ],
      facebookConversionWindowCombinedDataList: [
        { value: '0', label: this.$t('7-days-after-clicking-or-1-day-after-viewing') },
        { value: '1', label: this.$t('1-day-after-clicking-or-7-days-after-viewing') },
        { value: '2', label: this.$t('1-day-after-clicking-or-1-day-after-viewing') },
        { value: '3', label: this.$t('7-days-after-clicking-or-7-days-after-viewing') }
      ],
      adPreviewData: null,
      adFormat: ''
    }
  },
  computed: {
    ...mapGetters([
      'pages',
      'statuses',
      'creativeFormats',
      'filteredAgencies',
      'filteredClients',
      'filteredOrders',
      'filteredLineItems',
      'filteredCampaigns',
      'facebookPixels',
      'videoStatus',
      'callToAction'
    ]),
    formFields () {
      // This computed property handles every change on the form ad,
      // so it can be watched later to refresh the adPreviewData model
      return `
      ${this.name}
      ${this.statusId}
      ${this.facebookPageId}
      ${this.instagramPageId}
      ${this.format}
      ${this.imageFile}
      ${this.videoFile}
      ${this.text}
      ${this.title}
      ${this.description}
      ${this.link}
      ${this.caption}
      ${this.callToActionId}
      ${this.utmSource}
      ${this.utmMedium}
      ${this.utmCampaign}
      ${this.utmContent}
      ${this.facebookPixel}
      ${this.facebookPixelEvent}
      ${this.facebookConversionWindow}
      `
    },
    availableStatuses () {
      return this.statuses.filter(item => item.value === 'ACTIVE' || item.value === 'PAUSED')
    },
    availableFacebookPages () {
      return this.pages && this.pages.facebook ? this.pages.facebook.map(item => {
        return { value: item.api_id, label: item.name }
      }) : []
    },
    availableInstagramPages () {
      return this.pages && this.pages.instagram ? this.pages.instagram.map(item => {
        return { value: item.api_id, label: item.name }
      }) : []
    },
    fullLink () {
      let fullLink = this.link
      if (this.utmSource !== '' || this.utmMedium !== '' || this.utmCampaign !== '' || this.utmContent !== '') {
        fullLink = `${fullLink}${fullLink.charAt(fullLink.length - 1) !== '/' ? '/?' : '?'}`
        if (this.utmSource !== '') {
          fullLink = `${fullLink}utm_source=${encodeURIComponent(this.utmSource)}`
        }
        if (this.utmMedium !== '') {
          fullLink = `${fullLink}&utm_medium=${encodeURIComponent(this.utmMedium)}`
        }
        if (this.utmCampaign !== '') {
          fullLink = `${fullLink}&utm_campaign=${encodeURIComponent(this.utmCampaign)}`
        }
        if (this.utmContent !== '') {
          fullLink = `${fullLink}&utm_content=${encodeURIComponent(this.utmContent)}`
        }
      }
      return fullLink
    },
    availableCreativeFormatsValues () {
      if (this.creativeFormats && this.creativeFormats.facebook) {
        return [...this.creativeFormats.facebook.filter(item => [
          'RIGHT_COLUMN_STANDARD', 'DESKTOP_FEED_STANDARD', 'MOBILE_FEED_STANDARD', 'FACEBOOK_STORY_MOBILE'
        ].includes(item)).map(item => {
          return { label: this.fauxTranslateString(item), value: item }
        })]
      } else {
        return []
      }
    },
    filteredCallToActionValues () {
      if (this.campaign && this.campaign.objective && this.callToAction && this.callToAction.facebook) {
        return this.callToAction.facebook.map(item => {
          return { label: this.fauxTranslateString(item), value: item }
        })
      } else {
        return []
      }
    },
    creativeData () {
      let creativeData = new FormData()
      creativeData.append('account_id', this.campaign?.account_id)
      creativeData.append('adset', this.adset?.api_id)
      if (this.facebookConversionWindow !== '') {
        creativeData.append('attribution_spec', this.facebookConversionWindowDataList[this.facebookConversionWindow])
      }
      if (this.callToActionId !== undefined) {
        creativeData.append('call_to_action', this.callToActionId)
      }
      if (this.caption !== undefined && this.caption !== '') {
        creativeData.append('caption', this.caption)
      }
      if (this.adPreviewData?.creative_id) {
        creativeData.append('creative_id', this.adPreviewData?.creative_id)
      }
      creativeData.append('description', this.description)
      // TODO: FALTA EL format PARA EL RESTO DE FORMATOS
      creativeData.append('format', this.format)
      if (this.imageFile !== undefined) {
        creativeData.append('image', this.imageFile)
      }
      creativeData.append('instagram_actor_id', this.instagramPageId)
      creativeData.append('link', this.link)
      creativeData.append('name', this.name)
      creativeData.append('page', this.facebookPageId)
      creativeData.append('platform', this.adset?.platform)
      creativeData.append('ad_format', this.adFormat)
      if (this.facebookPixel !== '') {
        let promotedObject = {
          pixel_id: this.facebookPixel,
          custom_event_type: this.facebookPixelEvent
        }
        creativeData.append('promoted_object', promotedObject)
      }
      creativeData.append('text', this.text)
      creativeData.append('title', this.title)
      if (this.videoFile !== undefined) {
        creativeData.append('video', this.videoFile)
      }
      return creativeData
    },
    creativeDataObject () {
      let creativeDataObject = {}
      creativeDataObject.account_id = this.campaign?.account_id
      creativeDataObject.adset = this.adset?.api_id
      if (this.facebookConversionWindow !== '') {
        creativeDataObject.attribution_spec = this.facebookConversionWindowDataList[this.facebookConversionWindow]
      }
      if (this.callToActionId !== undefined) {
        creativeDataObject.call_to_action = this.callToActionId
      }
      if (this.caption !== undefined && this.caption !== '') {
        creativeDataObject.caption = this.caption
      }
      if (this.adPreviewData?.creative_id) {
        creativeDataObject.creative_id = this.adPreviewData?.creative_id
      }
      creativeDataObject.description = this.description
      creativeDataObject.format = this.format
      // if (this.imageFile !== undefined) {
      //   creativeDataObject.image = this.imageFile
      // }
      creativeDataObject.instagram_actor_id = this.instagramPageId
      creativeDataObject.link = this.link
      creativeDataObject.name = this.name
      creativeDataObject.page = this.facebookPageId
      creativeDataObject.platform = this.adset?.platform
      creativeDataObject.ad_format = this.adFormat
      if (this.facebookPixel !== '') {
        creativeDataObject.promoted_object = {
          pixel_id: this.facebookPixel,
          custom_event_type: this.facebookPixelEvent
        }
      }
      creativeDataObject.text = this.text
      creativeDataObject.title = this.title
      // if (this.videoFile !== undefined) {
      //   creativeDataObject.video = this.videoFile
      // }
      return creativeDataObject
    }
  },
  mounted () {
    this.setUpAdData()
    this.getStatuses()
    this.getCreativeFormats()
    let query = { directSearch: true, campaign_ids: [this.$route.params.id] }
    const onComplete = function ({ data }) {
      this.campaign = data[0]
      const accountId = this.campaign.account_id
      this.steps[0].label = this.campaign.name
      this.getFacebookPixels({ id: this.campaign.account_id })
      let query = { directSearch: true, campaign_ids: [this.$route.params.id], adset_ids: [this.$route.params.adsetid] }
      const onComplete = function ({ data }) {
        this.adset = data[0]
        this.steps[1].label = this.adset.name
      }
      query.onComplete = onComplete.bind(this)
      this.getAdsets(query)
      this.getPagesFacebook({ account_id: accountId })
      this.getFacebookCallToAction()
    }
    query.onComplete = onComplete.bind(this)
    this.getCampaigns(query)
  },
  watch: {
    facebookPixels (newValue, oldValue) {
      this.facebookPixelDataList = newValue.map(item => {
        return { label: item.name, value: item.id }
      }) || []
    },
    facebookCustomEvents (newValue, oldValue) {
      this.facebookPixelEventDataList = newValue || []
    },
    facebookPageId (newValue, oldValue) {
      this.getPagesInstagram({ page_id: newValue })
    },
    formFields () {
      // Watches the hole ad form data, whenever it changes resets adPreviewData,
      // this way a new creative is created whenever any data has been changed
      this.adPreviewData = undefined
    }
  },
  methods: {
    ...mapActions({
      showNotification: SHOW_NOTIFICATION,
      getCampaigns: GET_CAMPAIGNS,
      getStatuses: GET_STATUSES,
      getCreativeFormats: GET_CREATIVE_FORMATS,
      getAdsets: GET_ADSETS,
      getTargetingFacebook: GET_TARGETING_FACEBOOK,
      getFacebookPixels: GET_FACEBOOK_PIXELS,
      getFacebookCustomEvents: GET_FACEBOOK_CUSTOM_EVENTS,
      getPagesFacebook: GET_PAGES_FACEBOOK,
      getPagesInstagram: GET_PAGES_INSTAGRAM,
      getFacebookCallToAction: GET_FB_CALL_TO_ACTION,
      saveNewAd: SAVE_NEW_CREATIVE,
      uploadVideo: UPLOAD_VIDEO,
      getVideoStatus: GET_VIDEO_STATUS,
      finishVideoStatusProcessing: FINISH_VIDEO_STATUS_PROCESSING
    }),
    calcImageDimensions () {
      const w = this.$refs['image-preview'].naturalWidth
      const h = this.$refs['image-preview'].naturalHeight
      const r = this.gcd(w, h)
      this.imageDimensions = `${this.$t('dimensions')}: ${w}x${h}px, ${w / r}:${h / r} ${this.$t('aspect-ratio')}`
    },
    gcd (a, b) {
      return (b === 0) ? a : this.gcd(b, a % b)
    },
    setUpAdData () {
      this.platformId = 1 // DE MOMENTO!!!
      this.name = ''
      this.statusId = 'PAUSED'
      this.facebookPageId = undefined
      this.instagramPageId = undefined
      this.format = 'single_image'
      this.imageFile = undefined
      this.videoFile = undefined
      this.text = ''
      this.title = ''
      this.description = ''
      this.link = ''
      this.caption = ''
      this.callToActionId = ''
      this.showCreateUrlParameters = false
      this.utmSource = ''
      this.utmMedium = ''
      this.utmCampaign = ''
      this.utmContent = ''
      this.facebookPixel = ''
      this.facebookPixelEvent = ''
      this.facebookConversionWindow = null
      this.adPreviewData = null
      this.adFormat = ''
    },
    processImageFile (event) {
      this.imageFile = event.target.files[0]
    },
    validImageFileType (file) {
      // https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types
      // https://www.facebook.com/business/help/523719398041952?id=1240182842783684
      const fileTypes = [
        'image/bmp',
        'image/dib',
        'image/gif',
        'image/heic',
        'image/heif',
        'image/iff',
        'image/jfif',
        'image/jp2',
        'image/jpe',
        'image/jpeg',
        'image/jpg',
        'image/png',
        'image/psd',
        'image/tif',
        'image/tiff',
        'image/wbmp',
        'image/webp',
        'image/xbm'
      ]
      return fileTypes.includes(file.type)
    },
    getFileBlob (file) {
      return URL.createObjectURL(file)
    },
    processVideoFile (event) {
      this.videoFile = event.target.files[0]
      // let query = {
      //   account_id: this.campaign.account_id,
      //   name: this.name !== '' ? this.name : 'ad_video',
      //   platform: this.adset.platform,
      //   video: this.videoFile
      // }
      // const onComplete = function (responseData) {
      //   this.showNotification({ text: this.$t('video-processing'), type: 'success', icon: 'success' })
      //   this.checkVideoStatus(responseData.data.id)
      // }
      // query.onComplete = onComplete.bind(this)
      // this.uploadVideo(query)
    },
    checkVideoStatus (videoId) {
      let query = { id: videoId }
      const onComplete = function (responseData) {
        if (responseData.data.status.toLowerCase() === 'ready') {
          this.showNotification({ text: this.$t('video-processed'), type: 'success', icon: 'success' })
          this.finishVideoStatusProcessing({ id: videoId })
        } else if (responseData.data.status.toLowerCase() === 'processing') {
          window.setTimeout(() => this.checkVideoStatus(videoId), 5000)
        } else {
          this.showNotification({ text: this.$t('video-processing-error'), type: 'error', icon: 'error' })
        }
      }
      query.onComplete = onComplete.bind(this)
      this.getVideoStatus(query)
    },
    validVideoFileType (file) {
      // https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Containers
      const fileTypes = [
        'video/3g2',
        'video/3gp',
        'video/3gpp',
        'video/asf',
        'video/avi',
        'video/dat',
        'video/divx',
        'video/dv',
        'video/f4v',
        'video/flv',
        'video/gif',
        'video/m2ts',
        'video/m4v',
        'video/mkv',
        'video/mod',
        'video/mov',
        'video/mp4',
        'video/mpe',
        'video/mpeg',
        'video/mpeg4',
        'video/mpg',
        'video/mts',
        'video/nsv',
        'video/ogm',
        'video/ogv',
        'video/qt',
        'video/quicktime',
        'video/tod',
        'video/ts',
        'video/vob',
        'video/wmv'
      ]
      return fileTypes.includes(file.type)
    },
    returnFileSize (number) {
      if (number < 1024) {
        return `${number}bytes`
      } else if (number >= 1024 && number < 1048576) {
        return `${(number / 1024).toFixed(1)}KB`
      } else if (number >= 1048576) {
        return `${(number / 1048576).toFixed(1)}MB`
      }
    },
    capitalizeFirstLetter (string) {
      return `${string.charAt(0).toUpperCase()}${string.slice(1)}`
    },
    fauxTranslateString (string) {
      return this.capitalizeFirstLetter(string.toLowerCase().replaceAll('_', ' '))
    },
    // EVENTS:
    onSubmit () {
      // TODO: Only save new creative if some data has been changed after generating a preview.
      if (this.$refs['create-form'].reportValidity()) {
        let query = {
          account_id: this.campaign.account_id,
          name: this.name,
          adset: this.adset.api_id,
          platform: this.adset.platform,
          status: this.statusId,
          creativeForm: this.creativeData,
          creative: this.creativeDataObject
        }
        if (this.adPreviewData !== undefined && this.adPreviewData.creative_id !== undefined) {
          query.creative.creative_id = this.adPreviewData.creative_id
        } else {
          query.createAdAfterCreative = true
        }
        const onComplete = function (responseData) {
          this.showNotification({ text: this.$t('ad-created'), type: 'success', icon: 'success' })
          this.$router.push({ path: `/campaign/${this.campaign._id}/summary`, query: this.$route.query })
        }
        query.onComplete = onComplete.bind(this)
        this.saveNewAd(query)
      }
    },
    onPreviewSubmit () {
      if (this.$refs['create-form'].reportValidity()) {
        // Send only creative data:
        let query = {
          createAdAfterCreative: false,
          creativeForm: this.creativeData
        }
        const onComplete = function (responseData) {
          this.adPreviewData = responseData
        }
        query.onComplete = onComplete.bind(this)
        this.saveNewAd(query)
      }
    },
    onCancel () {
      this.$router.push({ name: 'dashboard', params: { tab: 'ads' }, query: this.$route.query })
    }
  }
}
</script>
<i18n src="@/core/i18n-common.json"></i18n>
<style scoped>
/* TODO: INTEGRAR ESTILOS CON LOS ESTILOS GENERALES */
#campaign-adset-ad-view {
  margin: 0;
  padding: 0 0 100px;
  display: flex;
  flex-direction: column;
}
.campaign-header {
  margin: 20px auto 0;
  width: calc(100% - 40px);
  max-width: 1200px;
}
#main-card {
  display: block;
  margin: 20px auto 0;
  width: calc(100% - 40px);
  max-width: 1200px;
}
.preview-wrapper {
  margin: 20px auto 20px;
  display: grid;
  grid-template-columns: 1fr 500px;
  grid-template-rows: 1fr;
  grid-template-areas:
    "col1 col-preview"
    "col2 col-preview";
  grid-column-gap: 20px;
}
.ad-preview {
  grid-area: col-preview;
}
.ad-preview iframe {
  width: 100%;
}
.card__content + .card__content {
  margin-top: 20px;
}
.card__header-step {
  background-color: var(--app-background-color);
}
.campaign-header {
  display: flex;
  line-height: 36px;
  justify-content: space-between;
  padding: 10px 0;
}
.pills-wrapper {
  justify-content: space-around;
  align-items: inherit;
  padding-bottom: 40px;
}
.pill {
  background-color: var(--app-light-blue);
  border-radius: 20px;
  text-align: center;
  flex-basis: 200px;
  padding: 0 7px 7px !important;
}
.pill label {
  margin-top: 100px;
  left: 0;
  text-align: left;
  display: block;
  position: relative;
}
.pill label.--ta-c {
  text-align: center;
}
.pill label::before {
  background-position-y: 4px !important;
}
.pill-img {
  filter: var(--app-png-dark-grey-filter);
  position: absolute;
  top: -96px;
  left: calc(100% - 134px);
  height: 96px;
  width: 96px;
}
.pill-img-blue {
  filter: var(--app-png-dark-blue-filter);
}
.pill-img-red {
  filter: var(--app-png-dark-red-filter);
}
.pill-img-green {
  filter: var(--app-png-medium-green-filter);
}
.pill-img-ochre {
  filter: var(--app-png-ochre-filter);
}
.pill label[disabled] {
  color: var(--app-disabled-color);
}
.pill label[disabled] .pill-img,
.pill label[disabled]::before {
  filter: var(--app-png-disabled-filter);
}
.preview-files-wrapper {
  display: flex;
  padding-bottom: 20px;
}
.preview-files-wrapper + .preview-files-wrapper {
  border-top: 2px solid var(--app-lines-color);
  padding-top: 30px;
}
.preview-file {
  flex-basis: 100%;
  padding: 0 10px 10px;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-template-rows: 1fr 30px;
  grid-template-areas:
    "thumbnail 1st-row"
    "thumbnail 2nd-row";
  gap: 10px;
}
/* @keyframes placeHolderShimmer {
  0% {
    background-position: 0px 0;
  }
  100% {
    background-position: 100em 0;
  }
} */
.preview-file--is-processing {
  padding-top: 10px;
}
.preview-file--is-processing::before {
  content: '';
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: linear-gradient(270deg, var(--app-dark-blue), var(--app-light-blue), var(--app-white));
  background-size: 600% 600%;
  -webkit-animation: isLoading 4s ease infinite;
  -moz-animation: isLoading 4s ease infinite;
  animation: isLoading 4s ease infinite;
  transition: all ease .3s;
}
/* .preview-file--processing {
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: fff;
  background: linear-gradient(to right, var(--app-light-blue) 8%, var(--app-dark-blue) 18%, var(--app-light-blue) 33%);
  height: 100%;
  width: 100%;
  position: absolute;
  padding-top: 50px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  left:0;
  right:0;
  top:0;
  bottom:0;
} */
.preview-file + .preview-file {
  border-bottom: 1px solid var(--app-lines-color);
  margin-bottom: 10px;
}
.preview-file-thumbnail {
  width: 200px;
  grid-area: thumbnail;
}
.preview-file-clear-button {
  width: 100px;
}
/* .wizard-form {
  margin-top: 20px;
} */
.form__field {
  position: relative;
  padding: 0 0 7px;
  margin: 20px 0 0 0;
  border: none;
}
.form__field--only-text {
  padding: 0 20px 7px;
}
.form__field--no-label {
  margin-top: 0;
}
.input {
  border-width: 0;
  /* background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2); */
  /* background-size: 0 2px, 100% 1px; */
  /* background-repeat: no-repeat; */
  /* background-position: center bottom, center calc(100% - 1px); */
  /* background-color: transparent; */
  box-shadow: none;
  /* border-radius: 0; */
  color: var(--app-font-color);
  float: none;
  font-size: var(--app-base-fontsize);
  font-weight: var(--app-font-weight);
  height: 36px;
  line-height: var(--app-line-height);
  margin: 0 0 7px;
  padding: 7px 0;
  /* transition: background 0s ease-out; */
  width: 100%;
  outline: none;
}
.input:disabled {
  color: var(--app-disabled-color);
  cursor: default;
  /* background-color: rgba(235, 235, 228, 0.3); */
}
.input:-internal-autofill-selected {
  /* appearance: menulist-button; */
  /* background-color: #e8f0fe !important; */
  background-color: var(--app-light-blue) !important;
  /* background-image: none !important; */
  /* color: -internal-light-dark(black, white) !important; */
}
.input__text {
  padding: 7px 20px;
}
.input__date {
  padding: 7px 0px 7px 20px;
}
/* .input__date {
  background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2), url('/img/baseline_today_black_48dp.png');
  background-size: 0 2px, 100% 1px, 24px 24px;
  background-repeat: no-repeat;
  background-position: center bottom, center calc(100% - 1px), calc(100% - 3px) 3px;
  background-color: transparent;
}
.input__date--from {
  background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2), url('/img/baseline_today_black_48dp.png');
}
.input__date--to {
  background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2), url('/img/baseline_event_black_48dp.png');
}
.input__text:focus {
  outline: none;
  background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2);
  background-size: 100% 2px, 100% 1px;
  box-shadow: none;
  transition-duration: 0.3s;
} */
.input__label {
  color: var(--app-disabled-color);
  cursor: text;
  font-size: var(--app-base-fontsize);
  font-weight: var(--app-font-weight);
  line-height: var(--input-line-height);
  margin: 10px 0 0;
  position: absolute;
  pointer-events: none;
  transition: 0.3s ease all;
  top: -7px;
  left: 20px;
  will-change: left, top, contents;
}
.input__date ~ .input__label,
.input__text:focus ~ .input__label,
.input__text:valid:not(.input--empty) ~ .input__label,
.input__text:-webkit-autofill ~ .input__label,
.input__text:read-only ~ .input__label,
.input__text:disabled ~ .input__label,
.input__label--focus,
.input__label__multivalue,
.input__label--invalid,
.input__text--invalid,
.input__text:invalid,
.input__text:invalid:not(:-webkit-autofill) ~ .input__label {
  top: -30px;
  left: 20px;
  font-size: 11px;
  line-height: 1;
}
.input__label--invalid,
.input__text--invalid,
.input__date__label--invalid,
.input__text:invalid,
.input__text:invalid:not(:-webkit-autofill) ~ .input__label {
  color: var(--app-dark-red);
}
/* .input__label__multivalue {
  margin: 10px 0 0;
  position: relative;
  display: block;
  top: unset;
  font-size: 11px;
  line-height: 1;
} */
.input__label__singlevalue {
  /* margin: 0 10px 10px 0; */
  margin: 5px 10px 10px 2px;
  border: none;
  border-radius: 4px;
  /* background-color: var(--card-background-color);
  background-image: linear-gradient(black, black), url('/img/baseline_radio_button_unchecked_white_48dp.png');
  background-repeat: no-repeat;
  background-position-x: 3px;
  background-position-y: 3px;
  background-size: 25px;
  background-blend-mode: difference; */
  line-height: var(--input-line-height);
  padding: 0px 10px 0px 25px;
  position: relative;
  display: inline-block;
  top: unset;
  pointer-events: unset;
  cursor: pointer;
  color: black;
}
/* .input__label__singlevalue.input__label--checkbox {
  background-image: linear-gradient(black, black), url('/img/baseline_check_box_outline_blank_white_48dp.png');
} */
.input__radio--checked + .input__label__singlevalue.input__label--radio,
.input__checkbox--checked + .input__label__singlevalue.input__label--checkbox {
  /* background-image: linear-gradient(black, black), url('/img/baseline_check_box_white_48dp.png'); */
  color: var(--app-dark-blue);
}
.input__label__singlevalue:hover {
    outline: none;
    box-shadow: var(--app-shadow-color);
}
.input__radio {
  display: none;
}
/* .input__radio--checked + .input__label__singlevalue,
.input__radio[checked] + .input__label__singlevalue {
    background-image: linear-gradient(black, black), url('/img/baseline_radio_button_checked_white_48dp.png');
} */
.input__radio--disabled + .input__label__singlevalue:hover {
    box-shadow: none;
    cursor: default;
}
.input__select--multiple {
  height: 60px;
  padding: 0;
}
.input__textarea,
.input__select--multiple--large {
  height: 120px;
  margin-top: 5px;
  border-radius: 10px;
  overflow: auto;
  text-indent: 0;
  background-image: none;
}
.tags-list {
  margin-top: 5px;
}
.tags-list--w100 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  height: 125px;
  overflow: auto;
  max-width: 700px;
  width: 700px;
  box-sizing: border-box;
}
.tag {
  max-width: calc(100% - 40px);
  position: relative;
  padding-right: 40px;
}
.tag .btn__img {
  position: absolute;
  right: 10px;
  top: calc(50% - 12px);
  margin: 0;
  padding: 0;
}
.input__fake {
  opacity: 0;
  width: 0;
  height: 0;
}
option {
  padding: 5px 20px;
}
option:checked {
  background-color: var(--app-dark-blue) !important;
  color: var(--app-white) !important;
}
</style>
