<template>
  <main id="campaign-adset-view">
    <div class="campaign-header card__header card__header--with-steps">
      <h4 v-for="(step, index) in steps" :key="index" :class="`card__header-step card__header-step--${step.status}`">
        <span class="step-value">{{ step.value }}</span>
        <span class="step-label">{{ step.label }}</span>
      </h4>
    </div>
    <div class="audience-wrapper">
      <div class="card" id="main-card" v-if="!canCreateAdset">
        <div class="card__content">
          <div class="card__header">
            <fieldset class="form__field form__field--no-label form__field--only-text --w100">
              <p class="--bold">{{ $t('cant-create-adset') }}</p>
              <p class="--hint-text">{{ $t('cant-create-adset-conversion-pixel') }}</p>
            </fieldset>
          </div>
        </div>
        <div class="modal__actions">
          <div class="modal__actions--left">
            <button type="button" class="btn btn--text" @click="onCancel">{{ $t('cancel') }}</button>
          </div>
          <div class="modal__actions--right">
            &nbsp;
          </div>
        </div>
      </div>
      <div class="card" id="main-card" v-else>
        <form action="#create" class="wizard-form" @submit="onSubmit" ref="create-form">
        <div class="card__content">
          <div class="card__header">
            <h4 class="card__title">{{ $t('ad-set-name') }}</h4>
          </div>
          <div class="card__header">
            <fieldset class="form__field form__field--no-label --w100">
              <input type="text" class="input input__text" required name="name" id="name" v-model="name" autocomplete="new-password">
            </fieldset>
          </div>
        </div>
        <div class="card__content">
          <div class="card__header">
            <h4 class="card__title">{{ $t('calendar') }}</h4>
          </div>
          <div class="card__header">
            <fieldset class="form__field --w25">
              <input type="datetime-local" class="input input__date input__date--from" required name="startDate" id="startDate" v-model="startDate" autocomplete="new-password">
              <label for="startDate" class="input__label">{{ $t('start-date') }} *</label>
            </fieldset>
            <!-- <fieldset class="form__field --w25">
              <input type="time" class="input input__text" required name="startDateTime" id="startDateTime" v-model="startDateTime" autocomplete="new-password">
            </fieldset> -->
            <fieldset class="form__field --w25">
              <input type="datetime-local" class="input input__date input__date--to" required name="endDate" id="endDate" v-model="endDate" autocomplete="new-password">
              <label for="endDate" class="input__label">{{ $t('end-date') }} *</label>
            </fieldset>
            <!-- <fieldset class="form__field --w25">
              <input type="time" class="input input__text" required name="endDateTime" id="endDateTime" v-model="endDateTime" autocomplete="new-password">
            </fieldset> -->
            <fieldset class="form__field --w25">&nbsp;</fieldset>
          </div>
        </div>
        <div class="card__content">
          <div class="card__header">
            <h4 class="card__title">{{ $t('expense-optimization-controls') }}</h4>
          </div>
          <!-- <div class="card__header" v-if="campaign.objective === 'CONVERSIONS'"> -->
          <div class="card__header">
            <fieldset class="form__field --w50">
              <label for="optimizationGoalId" class="input__label input__label__multivalue">{{ $t('optimization-goal') }} *</label>
              <select class="input input__select" required name="optimizationGoalId" id="optimizationGoalId" v-model="optimizationGoalId">
                <option value="" disabled selected>{{ $t('please-choose') }}</option>
                <option
                  v-for="(dataItem, index) in availableOptimizationGoals"
                  :value="dataItem.value"
                  :key="index">{{ $t(`optimization-goal-${dataItem.label}`) }}</option>
              </select>
            </fieldset>
          </div>
          <div class="card__header" v-if="campaign.bid_strategy === 'LOWEST_COST_WITH_BID_CAP' || campaign.bid_strategy === 'COST_CAP'">
            <fieldset class="form__field --w25">
              <input type="number" pattern="[0-9]+([\.,][0-9]+)?" min="0" step="0.01" inputmode="decimal" class="input input__text" required name="bidAmount" id="bidAmount" v-model="bidAmount" autocomplete="new-password">
              <label for="bidAmount" class="input__label">{{ $t('bid-amount') }} *</label>
            </fieldset>
          </div>
          <div class="card__header" v-else>
            <fieldset class="form__field form__field--no-label form__field--only-text --w100">
              <p class="--bold">{{ $t('lowest-cost-control-1') }}.</p>
              <p class="--hint-text">{{ $t('lowest-cost-control-2') }}.</p>
            </fieldset>
          </div>
          <div class="card__header">
            <fieldset class="form__field --w50">
              <label for="billingEventId" class="input__label input__label__multivalue">{{ $t('billing-event') }} *</label>
              <select class="input input__select" required name="billingEventId" id="billingEventId" v-model="billingEventId">
                <option value="" disabled selected>{{ $t('please-choose') }}</option>
                <option
                  v-for="(dataItem, index) in billingEvents"
                  :value="dataItem.value"
                  :key="index">{{ $t(`billing-event-${dataItem.label}`) }}</option>
              </select>
            </fieldset>
          </div>
          <div class="card__header" v-if="this.campaign && (this.campaign.daily_budget === null && this.campaign.lifetime_budget === null)">
            <fieldset class="form__field --w25">
              <label for="budgetPeriod" class="input__label input__label__multivalue">{{ $t('adset-budget') }} *</label>
              <select class="input input__select" required name="budgetPeriod" id="budgetPeriod" v-model="budgetPeriod">
                <option value="daily" selected>{{ $t('daily-budget') }}</option>
                <option value="lifetime">{{ $t('lifetime-budget') }}</option>
              </select>
            </fieldset>
            <fieldset class="form__field --w25" v-if="budgetPeriod === 'daily'">
              <label for="dailyBudget" class="input__label input__label__multivalue">{{ $t('budget') }} (€) *</label>
              <input type="number" pattern="[0-9]+([\.,][0-9]+)?" min="0.1" step="0.1" maxlength="10" inputmode="decimal" class="input input__text" required name="dailyBudget" id="dailyBudget" v-model="dailyBudget" autocomplete="new-password">
            </fieldset>
            <fieldset class="form__field --w25" v-else>
              <label for="lifetimeBudget" class="input__label input__label__multivalue">{{ $t('budget') }} (€) *</label>
              <input type="number" pattern="[0-9]+([\.,][0-9]+)?" min="0.1" step="0.1" maxlength="10" inputmode="decimal" class="input input__text" required name="lifetimeBudget" id="lifetimeBudget" v-model="lifetimeBudget" autocomplete="new-password">
            </fieldset>
            <fieldset class="form__field --w50">&nbsp;</fieldset>
          </div>
        </div>
        <div class="card__content">
          <div class="card__header">
            <h4 class="card__title">{{ $t('location') }}</h4>
          </div>
          <div class="card__header">
            <fieldset class="form__field form__field--no-label --w100">
              <input type="text" class="input input__text" required name="locationSearchText" id="locationSearchText" v-model="locationSearchText" autocomplete="new-password" @change="onLocationSearchTextChange" :placeholder="$t('search')">
            </fieldset>
          </div>
          <div class="card__header">
            <fieldset class="form__field form__field--no-label --w100">
              <select class="input input__select input__select--multiple input__select--multiple--large"
                id="locationSearchResults" v-model="locationSearchSelection" size="5" multiple required>
                <option
                  v-for="(dataItem, index) in locationSearchDataList"
                  :value="dataItem.value"
                  :selected="locationSearchSelection.includes(dataItem.value)"
                  :key="index">{{ dataItem.label }}</option>
              </select>
            </fieldset>
          </div>
        </div>
        <div class="card__content">
          <div class="card__header">
            <h4 class="card__title">{{ $t('demographic-data') }}</h4>
          </div>
          <div class="card__header">
            <fieldset class="form__field --w25">
              <label for="ageMin" class="input__label input__label__multivalue">{{ `${this.$t('age')} (${this.$t('min')})` }}</label>
              <select class="input input__select" name="ageMin" id="ageMin" v-model="ageMin">
                <option
                  v-for="(value, index) in minMaxAgeRange"
                  :selected="index === 0"
                  :value="value"
                  :key="index">{{ value }}</option>
              </select>
            </fieldset>
            <fieldset class="form__field --w25">
              <label for="ageMin" class="input__label input__label__multivalue">{{ `${this.$t('age')} (${this.$t('max')})` }}</label>
              <select class="input input__select" name="ageMax" id="ageMax" v-model="ageMax">
                <option
                  v-for="(value, index) in minMaxAgeRange"
                  :selected="index === minMaxAgeRange.length - 1"
                  :value="value"
                  :key="index">{{ value }}</option>
              </select>
            </fieldset>
            <fieldset class="form__field --w50">
              <label class="input__label input__label__multivalue">{{ this.$t('gender') }}</label>
              <input class="input__checkbox"
                :class="[ gender.includes('MALE') ? 'input__checkbox--checked' : '' ]"
                type="checkbox"
                v-model="gender"
                :checked="gender.includes('MALE')"
                value="MALE"
                id="genderMale"
                name="gender" />
              <label class="input__label input__label--checkbox input__label__singlevalue" for="genderMale">{{ $t('men') }}</label>
              <input class="input__checkbox"
                :class="[ gender.includes('FEMALE') ? 'input__checkbox--checked' : '' ]"
                type="checkbox"
                v-model="gender"
                :checked="gender.includes('FEMALE')"
                value="FEMALE"
                id="genderFemale"
                name="gender" />
              <label class="input__label input__label--checkbox input__label__singlevalue" for="genderFemale">{{ $t('women') }}</label>
            </fieldset>
          </div>
        </div>
        <div class="card__content">
          <div class="card__header">
            <h4 class="card__title">{{ $t('placement') }}</h4>
          </div>
          <div class="card__header">
            <fieldset class="form__field form__field--no-label --w100">
              <input
                class="input__radio"
                :class="[ placement === 'automatic' ? 'input__radio--checked' : '' ]"
                type="radio"
                v-model="placement"
                value="automatic"
                id="placementAutomatic"
                name="placement" />
              <label
                class="input__label input__label--radio input__label__singlevalue"
                for="placementAutomatic">{{ $t('automatic-placement') }}</label>
              <input
                class="input__radio"
                :class="[ placement === 'manual' ? 'input__radio--checked' : '' ]"
                type="radio"
                v-model="placement"
                value="manual"
                id="placementManual"
                name="placement" />
              <label
                class="input__label input__label--radio input__label__singlevalue"
                for="placementManual">{{ $t('manual-placement') }}</label>
            </fieldset>
          </div>
          <div class="card__header" v-if="placement === 'manual'">
            <fieldset class="form__field --w100">
              <label class="input__label input__label__multivalue">{{ this.$t('devices') }}</label>
              <input class="input__checkbox"
                :class="[ devices.includes('MOBILE') ? 'input__checkbox--checked' : '' ]"
                type="checkbox"
                v-model="devices"
                :checked="devices.includes('MOBILE')"
                value="MOBILE"
                id="devicesMobile"
                name="devices" />
              <label class="input__label input__label--checkbox input__label__singlevalue" for="devicesMobile">{{ $t('mobile') }}</label>
              <input class="input__checkbox"
                :class="[ devices.includes('DESKTOP') ? 'input__checkbox--checked' : '' ]"
                type="checkbox"
                v-model="devices"
                :checked="devices.includes('DESKTOP')"
                value="DESKTOP"
                id="devicesDesktop"
                name="devices" />
              <label class="input__label input__label--checkbox input__label__singlevalue" for="devicesDesktop">{{ $t('desktop') }}</label>
            </fieldset>
          </div>
          <div class="card__header" v-if="placement === 'manual'">
            <fieldset class="form__field --w100">
              <label class="input__label input__label__multivalue">{{ this.$t('platforms') }}</label>
              <input class="input__checkbox"
                :class="[ platforms.includes('FACEBOOK') ? 'input__checkbox--checked' : '' ]"
                type="checkbox"
                v-model="platforms"
                :checked="platforms.includes('FACEBOOK')"
                value="FACEBOOK"
                id="platformsFacebook"
                name="platforms" />
              <label class="input__label input__label--checkbox input__label__singlevalue" for="platformsFacebook">{{ $t('facebook') }}</label>
              <input class="input__checkbox"
                :class="[ platforms.includes('INSTAGRAM') ? 'input__checkbox--checked' : '' ]"
                type="checkbox"
                v-model="platforms"
                :checked="platforms.includes('INSTAGRAM')"
                value="INSTAGRAM"
                id="platformsInstagram"
                name="platforms" />
              <label class="input__label input__label--checkbox input__label__singlevalue" for="platformsInstagram">{{ $t('instagram') }}</label>
              <input class="input__checkbox"
                :class="[ platforms.includes('AUDIENCE_NETWORK') ? 'input__checkbox--checked' : '' ]"
                type="checkbox"
                v-model="platforms"
                :checked="platforms.includes('AUDIENCE_NETWORK')"
                value="AUDIENCE_NETWORK"
                id="platformsAudienceNetwork"
                name="platforms" />
              <label class="input__label input__label--checkbox input__label__singlevalue" for="platformsAudienceNetwork">{{ $t('audience-network') }}</label>
            </fieldset>
          </div>
        </div>
        <div class="card__content">
          <div class="card__header">
            <h4 class="card__title">{{ $t('advanced-search') }}</h4>
          </div>
          <div class="card__header">
            <fieldset class="form__field form__field--no-label --w100">
              <input type="text" class="input input__text" required name="advancedSearchText" id="advancedSearchText" v-model="advancedSearchText" autocomplete="new-password" @change="onAdvancedSearchTextChange"
                :placeholder="`${this.$t('search')}: ${this.$t('interests')}, ${this.$t('behaviors')}, ${this.$t('life-events')}, ${this.$t('industries')}, ${this.$t('work-employers')}, ${this.$t('work-positions')}`">
            </fieldset>
          </div>
          <div class="card__header">
            <fieldset class="form__field form__field--no-label --w100">
              <select class="input input__select input__select--multiple input__select--multiple--large"
                id="advancedSearchResults" v-model="advancedSearchSelection" size="5" multiple required>
                <option
                  v-for="(dataItem, index) in advancedSearchDataList"
                  :value="dataItem.value"
                  :selected="advancedSearchSelection.includes(dataItem.value)"
                  :key="index">{{ dataItem.label }}</option>
              </select>
            </fieldset>
          </div>
        </div>
        <div class="card__content" v-if="campaign && campaign.objective && campaign.objective.toLowerCase() === 'conversions'">
          <div class="card__header">
            <h4 class="card__title">{{ $t('conversion-tracking') }}</h4>
          </div>
          <div class="card__header">
            <fieldset class="form__field --w100">
              <label for="facebookPixel" class="input__label input__label__multivalue">{{ this.$t('pixel-facebook') }} *</label>
              <select class="input input__select" id="facebookPixel" name="facebookPixel" v-model="facebookPixel" required>
                <option value="" disabled selected>{{ $t('please-choose') }}</option>
                <option
                  v-for="(dataItem, index) in facebookPixelDataList"
                  :value="dataItem.value"
                  :key="index">{{ dataItem.label }}</option>
              </select>
            </fieldset>
          </div>
          <div class="card__header">
            <fieldset class="form__field --w100">
              <label for="facebookPixelEvent" class="input__label input__label__multivalue">{{ this.$t('event') }} *</label>
              <select class="input input__select" id="facebookPixelEvent" name="facebookPixelEvent" v-model="facebookPixelEvent" required>
                <option value="" disabled selected>{{ $t('please-choose') }}</option>
                <option
                  v-for="(dataItem, index) in facebookPixelEventDataList"
                  :value="dataItem.value"
                  :key="index">{{ dataItem.label }}</option>
              </select>
            </fieldset>
          </div>
          <!-- array de combinaciones, cada elemento del array es un array con dos valores del json -->
          <div class="card__header">
            <fieldset class="form__field --w100">
              <label for="facebookConversionWindow" class="input__label input__label__multivalue">{{ this.$t('conversion-window') }} *</label>
              <select class="input input__select"
                id="facebookConversionWindow" name="facebookConversionWindow" v-model="facebookConversionWindow"
                required>
                <option
                  v-for="(dataItem, index) in facebookConversionWindowCombinedDataList"
                  :value="dataItem.value"
                  :key="index">{{ dataItem.label }}</option>
              </select>
            </fieldset>
          </div>
        </div>
        <div class="modal__actions">
          <div class="modal__actions--left">
            <button type="button" class="btn btn--text" @click="onCancel">{{ $t('cancel') }}</button>
          </div>
          <div class="modal__actions--right">
            <button type="button" class="btn btn--new" @click="onSubmit">{{ $t('continue') }}</button>
          </div>
        </div>
        </form>
      </div>
      <div class="card" id="audience-card" v-if="Object.keys(audience).length > 0 && audience.facebook.estimate_ready">
        <div class="card__content">
          <div class="card__header">
            <h4 class="card__title">{{ $t('audience-size') }}</h4>
          </div>
          <div class="card__header audience-size-container">
            <!-- <div class="audience-size-graph-outer">
              <div class="audience-size-graph-inner"></div>
            </div>
            <p class="audience-size-gauge">
              <span>{{ $t('specific') }}</span>
              <span>{{ $t('large') }}</span>
            </p> -->
            <p>
              <span class="audience-size-number">{{ audience.facebook.users.toLocaleString() }}</span>
              <span>{{ $t('people') }}</span>
            </p>
            <p class="--hint-text">{{ $t('potencial-scope') }}</p>
            <!-- <p class="audience-size-insight">{{ $t('audience-text-short') }}</p> -->
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import {
  GET_ACCOUNTS,
  GET_STATUSES,
  GET_OBJECTIVES,
  GET_PLATFORMS,
  GET_BILLING_EVENTS,
  GET_OPTIMIZATION_GOALS,
  GET_SPECIAL_AD_CATEGORIES,
  GET_BID_STRATEGIES,
  SAVE_NEW_ADSET,
  HIDE_MODAL_VEIL,
  SHOW_MODAL_VEIL,
  SHOW_MODAL,
  HIDE_MODAL,
  SHOW_NOTIFICATION,
  GET_FB_ESTIMATED_REACH,
  GET_CAMPAIGNS,
  GET_TARGETING_FACEBOOK,
  GET_FACEBOOK_PIXELS,
  GET_FACEBOOK_CUSTOM_EVENTS
} from '@/core/action-types'
import {
  mapGetters,
  mapActions
} from 'vuex'
import i18n from '@/plugins/i18n'
export default {
  name: 'campaign-adset',
  components: {
  },
  data: function () {
    return {
      locale: i18n.locale,
      steps: [
        { value: '1', label: this.$t('campaign'), status: 'completed' },
        { value: '2', label: this.$t('ad-sets'), status: 'active' },
        { value: '3', label: this.$t('ads'), status: 'inactive' },
        { value: '4', label: this.$t('summary'), status: 'inactive' }
      ],
      campaign: {},
      platformId: '',
      accountId: '',
      name: '',
      startDate: undefined,
      endDate: undefined,
      optimizationGoalId: undefined,
      billingEventId: undefined,
      bidAmount: 0.00,
      dailyBudget: 0.00,
      lifetimeBudget: 0.00,
      locationSearchText: '',
      locationSearchDataList: [],
      locationSearchSelection: [],
      advancedSearchText: '',
      advancedSearchDataList: [],
      advancedSearchSelection: [],
      ageMin: '18',
      ageMax: '65',
      gender: [ 'MALE', 'FEMALE' ],
      placement: 'automatic',
      devices: [ 'MOBILE', 'DESKTOP' ],
      platforms: [ 'FACEBOOK', 'INSTAGRAM' ],
      budgetPeriod: 'daily',
      facebookPixel: '',
      facebookPixelDataList: [],
      facebookPixelDataListLoaded: false,
      facebookPixelEvent: '',
      facebookPixelEventDataList: [
        { value: 'ADD_PAYMENT_INFO', label: this.$t('ADD_PAYMENT_INFO') },
        { value: 'ADD_TO_CART', label: this.$t('ADD_TO_CART') },
        { value: 'ADD_TO_WISHLIST', label: this.$t('ADD_TO_WISHLIST') },
        { value: 'COMPLETE_REGISTRATION', label: this.$t('COMPLETE_REGISTRATION') },
        { value: 'CONTACT', label: this.$t('CONTACT') },
        { value: 'CONTENT_VIEW', label: this.$t('CONTENT_VIEW') },
        { value: 'CUSTOMIZE_PRODUCT', label: this.$t('CUSTOMIZE_PRODUCT') },
        { value: 'DONATE', label: this.$t('DONATE') },
        { value: 'FACEBOOK_SELECTED', label: this.$t('FACEBOOK_SELECTED') },
        { value: 'FIND_LOCATION', label: this.$t('FIND_LOCATION') },
        { value: 'INITIATED_CHECKOUT', label: this.$t('INITIATED_CHECKOUT') },
        { value: 'LEAD', label: this.$t('LEAD') },
        { value: 'LISTING_INTERACTION', label: this.$t('LISTING_INTERACTION') },
        { value: 'OTHER', label: this.$t('OTHER') },
        { value: 'PURCHASE', label: this.$t('PURCHASE') },
        { value: 'SCHEDULE', label: this.$t('SCHEDULE') },
        { value: 'SEARCH', label: this.$t('SEARCH') },
        { value: 'START_TRIAL', label: this.$t('START_TRIAL') },
        { value: 'SUBMIT_APPLICATION', label: this.$t('SUBMIT_APPLICATION') },
        { value: 'SUBSCRIBE', label: this.$t('SUBSCRIBE') }
      ],
      facebookConversionWindow: null,
      facebookConversionWindowDataList: [
        [
          { event_type: 'CLICK_THROUGH', window_days: '7' },
          { event_type: 'VIEW_THROUGH', window_days: '1' }
        ],
        [
          { event_type: 'CLICK_THROUGH', window_days: '1' },
          { event_type: 'VIEW_THROUGH', window_days: '7' }
        ],
        [
          { event_type: 'CLICK_THROUGH', window_days: '1' },
          { event_type: 'VIEW_THROUGH', window_days: '1' }
        ],
        [
          { event_type: 'CLICK_THROUGH', window_days: '7' },
          { event_type: 'VIEW_THROUGH', window_days: '7' }
        ]
      ],
      facebookConversionWindowCombinedDataList: [
        { value: '0', label: this.$t('7-days-after-clicking-or-1-day-after-viewing') },
        { value: '1', label: this.$t('1-day-after-clicking-or-7-days-after-viewing') },
        { value: '2', label: this.$t('1-day-after-clicking-or-1-day-after-viewing') },
        { value: '3', label: this.$t('7-days-after-clicking-or-7-days-after-viewing') }
      ],
      minMaxAgeRange: []
    }
  },
  computed: {
    ...mapGetters([
      'accounts',
      'statuses',
      'objectives',
      'billingEvents',
      'optimizationGoals',
      'specialAdCategories',
      'bidStrategies',
      'filteredAgencies',
      'filteredClients',
      'filteredOrders',
      'filteredLineItems',
      'filteredCampaigns',
      'audience',
      'facebookPixels'
    ]),
    availableStatuses () {
      return this.statuses.filter(item => item.value === 'ACTIVE' || item.value === 'PAUSED')
    },
    availableObjectives () {
      return this.objectives.filter(item => item.value === 'LINK_CLICKS' || item.value === 'CONVERSIONS')
    },
    availableOptimizationGoals () {
      return this.optimizationGoals.filter(item => item.value === 'LINK_CLICKS' || item.value === 'CONVERSIONS')
    },
    audienceQuery () {
      let countries = []
      countries = this.locationSearchDataList.filter(item => this.locationSearchSelection.includes(item.value) && item.type === 'country').map(item => item.value)
      /* if (countries.length === 0) {
        countries = this.locationSearchDataList.filter(item => this.locationSearchSelection.includes(item.value) && item.type !== 'country').map(item => item.country_code)
      } */
      const zips = this.locationSearchDataList.filter(item => this.locationSearchSelection.includes(item.value) && item.type === 'zip').map(item => item.value)
      const regions = this.locationSearchDataList.filter(item => this.locationSearchSelection.includes(item.value) && item.type === 'region').map(item => {
        return { key: item.key }
      })
      const cities = this.locationSearchDataList.filter(item => this.locationSearchSelection.includes(item.value) && item.type === 'city').map(item => {
        return { key: item.key, radius: 10, distance_unit: 'mile' }
      })
      const neighborhoods = this.locationSearchDataList.filter(item => this.locationSearchSelection.includes(item.value) && item.type === 'neighborhood').map(item => {
        return { key: item.key }
      })
      /* let customLocations = this.locationSearchDataList.filter(item => this.locationSearchSelection.includes(item.value) && !['country', 'zip', 'region', 'city', 'neighborhood'].includes(item.type)).map(item => {
        return { address_string: item.value }
      }) */
      // customLocations = [{ 'address_string': '1601 Willow Road, Menlo Park, CA', 'radius': 5 }, { 'latitude': 36, 'longitude': -121.0, 'radius': 5, 'distance_unit': 'kilometer' }]
      const interests = this.advancedSearchDataList.filter(item => this.advancedSearchSelection.includes(item.value) && item.type === 'interests').map(item => item.value)
      const behaviors = this.advancedSearchDataList.filter(item => this.advancedSearchSelection.includes(item.value) && item.type === 'behaviors').map(item => item.value)
      const lifeEvents = this.advancedSearchDataList.filter(item => this.advancedSearchSelection.includes(item.value) && item.type === 'life_events').map(item => item.value)
      const industries = this.advancedSearchDataList.filter(item => this.advancedSearchSelection.includes(item.value) && item.type === 'industries').map(item => item.value)
      const workEmployers = this.advancedSearchDataList.filter(item => this.advancedSearchSelection.includes(item.value) && item.type === 'work_employers').map(item => item.value)
      const workPositions = this.advancedSearchDataList.filter(item => this.advancedSearchSelection.includes(item.value) && item.type === 'work_positions').map(item => item.value)
      return {
        account_id: this.campaign.account_id,
        targeting: {
          geo_locations: {
            countries: countries.length > 0 ? countries : undefined,
            zips: zips.length > 0 ? zips : undefined,
            cities: cities.length > 0 ? cities : undefined,
            regions: regions.length > 0 ? regions : undefined,
            neighborhoods: neighborhoods.length > 0 ? neighborhoods : undefined
            // custom_locations: customLocations.length > 0 ? customLocations : undefined
          },
          genders: this.gender,
          age_min: this.ageMin,
          age_max: this.ageMax,
          device_platforms: this.placement === 'manual' ? this.devices.map(item => item.toLowerCase()) : undefined,
          publisher_platforms: this.placement === 'manual' ? this.platforms.map(item => item.toLowerCase()) : undefined,
          interests: interests.length > 0 ? interests : undefined,
          behaviors: behaviors.length > 0 ? behaviors : undefined,
          life_events: lifeEvents.length > 0 ? lifeEvents : undefined,
          industries: industries.length > 0 ? industries : undefined,
          work_employers: workEmployers.length > 0 ? workEmployers : undefined,
          work_positions: workPositions.length > 0 ? workPositions : undefined
        }
      }
    },
    canCreateAdset () {
      // Adsets can't be created for CONVERSION objective campaigns if there's not a valid pixel available:
      return !(this.campaign && this.campaign.objective && this.campaign.objective.toLowerCase() === 'conversions' && this.facebookPixelDataListLoaded && this.facebookPixelDataList.length === 0)
    }
  },
  mounted () {
    for (let i = 13; i < 66; i++) {
      this.minMaxAgeRange.push(String(i))
    }
    this.setUpAdsetData()
    this.getBillingEvents()
    this.getOptimizationGoals()
    let query = { directSearch: true, campaign_ids: [this.$route.params.id] }
    const onComplete = function ({ data }) {
      this.campaign = data[0]
      this.accountId = this.campaign.account_id
      this.steps[0].label = this.campaign.name
      const endDate = new Date(this.campaign.end_date)
      let isoString = endDate.toISOString()
      this.endDate = isoString.substring(0, 16)
      this.getFacebookPixels({ id: this.campaign.account_id })
    }
    query.onComplete = onComplete.bind(this)
    this.getCampaigns(query)
  },
  watch: {
    audienceQuery (newValue, oldValue) {
      if (newValue.targeting?.geo_locations?.countries?.length > 0 ||
        newValue.targeting?.geo_locations?.zips?.length > 0 ||
        newValue.targeting?.geo_locations?.cities?.length > 0 ||
        newValue.targeting?.geo_locations?.regions?.length > 0 ||
        newValue.targeting?.geo_locations?.neighborhoods?.length > 0) {
        this.getFacebookEstimatedReach(newValue)
      }
    },
    facebookPixels (newValue, oldValue) {
      this.facebookPixelDataListLoaded = true
      this.facebookPixelDataList = newValue.map(item => {
        return { label: item.name, value: item.id }
      }) || []
    },
    facebookCustomEvents (newValue, oldValue) {
      this.facebookPixelEventDataList = newValue || []
    }
  },
  methods: {
    ...mapActions({
      getAccounts: GET_ACCOUNTS,
      getStatuses: GET_STATUSES,
      getObjectives: GET_OBJECTIVES,
      getPlatforms: GET_PLATFORMS,
      getBillingEvents: GET_BILLING_EVENTS,
      getOptimizationGoals: GET_OPTIMIZATION_GOALS,
      getSpecialAdCategories: GET_SPECIAL_AD_CATEGORIES,
      getBidStrategies: GET_BID_STRATEGIES,
      saveNewAdset: SAVE_NEW_ADSET,
      showModalVeil: SHOW_MODAL_VEIL,
      hideModalVeil: HIDE_MODAL_VEIL,
      showModal: SHOW_MODAL,
      hideModal: HIDE_MODAL,
      showNotification: SHOW_NOTIFICATION,
      getFacebookEstimatedReach: GET_FB_ESTIMATED_REACH,
      getCampaigns: GET_CAMPAIGNS,
      getTargetingFacebook: GET_TARGETING_FACEBOOK,
      getFacebookPixels: GET_FACEBOOK_PIXELS,
      getFacebookCustomEvents: GET_FACEBOOK_CUSTOM_EVENTS
    }),
    setUpAdsetData () {
      const today = new Date()
      let isoString = today.toISOString()
      // DE MOMENTO:
      this.platformId = 1
      this.accountId = ''
      this.name = ''
      this.startDate = isoString.substring(0, 16)
      this.endDate = undefined
      this.optimizationGoalId = undefined
      this.bidAmount = undefined
      this.billingEventId = undefined
      this.bidAmount = 0.00
      this.dailyBudget = 0.00
      this.lifetimeBudget = 0.00
      this.locationSearchText = ''
      this.locationSearchDataList = []
      this.locationSearchSelection = []
      this.advancedSearchText = ''
      this.advancedSearchDataList = []
      this.advancedSearchSelection = []
      this.ageMin = '18'
      this.ageMax = '65'
      this.gender = [ 'MALE', 'FEMALE' ]
      this.placement = 'automatic'
      this.devices = [ 'MOBILE', 'DESKTOP' ]
      this.platforms = [ 'FACEBOOK', 'INSTAGRAM' ]
      this.budgetPeriod = 'daily'
      this.facebookPixel = ''
      this.facebookPixelEvent = ''
      this.facebookConversionWindow = null
    },
    fetchAccounts () {
      let query = { platform: this.platformId }
      if (this.clientId !== undefined) {
        query.team_id = this.clientId
      }
      this.getAccounts(query)
    },
    // AJUSTAR LAS FECHAS
    // EVENTS
    onSubmit () {
      if (this.$refs['create-form'].reportValidity()) {
        let query = {
          campaign_id: this.campaign._id,
          platform: this.platformId,
          account_id: this.accountId,
          name: this.name,
          start_date: this.startDate,
          end_date: this.endDate && this.endDate !== undefined ? this.endDate : null,
          billing_event: this.billingEventId,
          daily_budget: this.campaign.daily_budget === null && this.campaign.lifetime_budget === null ? null : this.dailyBudget,
          lifetime_budget: this.campaign.daily_budget === null && this.campaign.lifetime_budget === null ? null : this.lifetimeBudget,
          // optimization_goal: this.campaign.objective === 'CONVERSIONS' ? this.optimizationGoalId : null,
          optimization_goal: this.optimizationGoalId,
          // bid_amount: this.campaign.bid_strategy === 'LOWEST_COST_WITHOUT_BID_CAP' ? null : this.bidAmount,
          targeting: this.audienceQuery.targeting
        }
        // if (this.campaign.bid_strategy !== 'LOWEST_COST_WITHOUT_BID_CAP' && this.bidAmount > 0) {
        if ((this.campaign.bid_strategy === 'LOWEST_COST_WITH_BID_CAP' || this.campaign.bid_strategy === 'COST_CAP') && this.bidAmount > 0) {
          query.bid_amount = this.bidAmount
        }
        if (this.optimizeBudget ||
          this.budgetPeriod !== 'daily' ||
          (query.daily_budget !== undefined && (query.daily_budget === '' || query.daily_budget === '0.0'))) {
          query.daily_budget = null
        }
        if (this.optimizeBudget ||
          this.budgetPeriod === 'daily' ||
          (query.lifetime_budget !== undefined && (query.lifetime_budget === '' || query.lifetime_budget === '0.0'))) {
          query.lifetime_budget = null
        }
        if (this.campaign.objective.toLowerCase() === 'conversions') {
          query.promoted_object = {
            pixel_id: this.facebookPixel,
            custom_event_type: this.facebookPixelEvent
          }
          query.attribution_spec = this.facebookConversionWindowDataList[this.facebookConversionWindow]
        }

        const onComplete = function (responseData) {
          this.showNotification({ text: this.$t('adset-created'), type: 'success', icon: 'success' })
          this.$router.push({ path: `/campaign/${this.campaign._id}/adset/${responseData._id}/ad`, query: this.$route.query })
        }
        query.onComplete = onComplete.bind(this)
        this.saveNewAdset(query)
      }
    },
    onCancel () {
      this.$router.push({ name: 'dashboard', params: { tab: 'adsets' }, query: this.$route.query })
    },
    onLocationSearchTextChange (event) {
      const zipCodeFacebookRegExp = /^\d{4}$/
      if (this.locationSearchText.length > 2) {
        this.locationSearchDataList = []
        if (zipCodeFacebookRegExp.test(this.locationSearchText)) {
          let query = {
            type: 'adzipcode',
            query: this.locationSearchText
          }
          const onComplete = function (responseData) {
            if (responseData.data && responseData.data.length > 0) {
              this.locationSearchDataList.push(...responseData.data.map(item => {
                return { label: item.name, value: item.id, type: 'zip', country_code: item.country_code }
              }))
            }
          }
          query.onComplete = onComplete.bind(this)
          this.getTargetingFacebook(query)
        } else {
          let queryCountries = {
            type: 'adcountry',
            query: this.locationSearchText
          }
          const onCompleteCountries = function (responseData) {
            if (responseData.data && responseData.data.length > 0) {
              this.locationSearchDataList.push(...responseData.data.map(item => {
                return { label: item.name, value: item.country_code, type: 'country' }
              }))
            }
          }
          queryCountries.onComplete = onCompleteCountries.bind(this)
          this.getTargetingFacebook(queryCountries)
          let queryGeolocation = {
            type: 'adgeolocation',
            query: this.locationSearchText
          }
          const onCompleteGeolocation = function (responseData) {
            if (responseData.data && responseData.data.length > 0) {
              this.locationSearchDataList.push(...responseData.data.map(item => {
                let label
                let value
                let type
                const key = item.key
                if (item.type === 'country') {
                  label = item.name
                  value = item.country_code
                  type = 'country'
                } else {
                  label = value = `${item.name} (${item.region}, ${item.country_name})`
                  // type = 'custom_location'
                  type = item.type
                }
                return { key, label, value, type, country_code: item.country_code }
              }))
            }
          }
          queryGeolocation.onComplete = onCompleteGeolocation.bind(this)
          this.getTargetingFacebook(queryGeolocation)
        }
      }
    },
    onAdvancedSearchTextChange (event) {
      if (this.advancedSearchText.length > 2) {
        this.advancedSearchDataList = []
        const queries = [
          { type: 'adinterest', dataType: 'interests' },
          { type: 'adworkemployer', dataType: 'adworkemployer' },
          { type: 'adworkposition', dataType: 'adworkposition' },
          { type: 'adTargetingCategory', dataType: 'industries', class: 'industries' },
          { type: 'adTargetingCategory', dataType: 'behaviors', class: 'behaviors' },
          { type: 'adTargetingCategory', dataType: 'life_events', class: 'life_events' }
        ]
        for (let i = 0; i < queries.length; i++) {
          let query = {
            type: queries[i].type,
            query: this.advancedSearchText
          }
          if (queries[i].class) {
            query.class = queries[i].class
          }
          let onComplete = function (responseData) {
            if (responseData.data && responseData.data.length > 0) {
              let filteredResponse = responseData.data.filter(item => item.name.toLowerCase().indexOf(this.advancedSearchText.toLowerCase()) >= 0)
              this.advancedSearchDataList.push(...filteredResponse.map(item => {
                let label = item.name
                let value = item.id
                let type = queries[i].dataType
                if (item.audience_size) {
                  label = `${label} (size: ${item.audience_size.toLocaleString()})`
                }
                // DUDA: NO SABEMOS QUÉ SIGNIFICA 'COVERAGE'
                if (item.coverage) {
                  label = `${label} (coverage: ${item.coverage.toLocaleString()})`
                }
                return { label, value, type }
              }))
            }
          }
          query.onComplete = onComplete.bind(this)
          this.getTargetingFacebook(query)
        }
      }
    }
  }
}
</script>
<i18n src="@/core/i18n-common.json"></i18n>
<style scoped>
/* TODO: INTEGRAR ESTILOS CON LOS ESTILOS GENERALES */
#campaign-adset-view {
  margin: 0;
  padding: 0 0 100px;
  display: flex;
  flex-direction: column;
}
.campaign-header {
  margin: 20px auto 0;
  width: calc(100% - 40px);
  max-width: 1200px;
}
.audience-wrapper {
  display: grid;
  grid-template-columns: 1fr 400px;
  grid-column-gap: 20px;
}
#main-card {
  margin: 20px 0 0 auto;
  width: calc(100% - 40px);
  max-width: 800px;
}
#audience-card {
  margin-top: 20px;
  width: calc(100% - 40px);
  max-width: 400px;
  height: 400px;
  position: sticky;
  top: 20px;
  z-index: 1;
}
.audience-size-container {
  flex-wrap: wrap;
}
/* .audience-size-graph {} */
.audience-size-graph-inner {
  background-image: linear-gradient(to right, #FA7373, #FA7373),
    linear-gradient(to right, #EEEEFC, #EEEEFC),
    linear-gradient(to right, #00D198, #00D198);
  background-repeat: no-repeat;
  background-position: 0 0, 50% 0, 100% 0;
  background-size:  20% 100%, 60% 100%, 20% 100%;
  height: 100%;
  border-radius: inherit;
}
.audience-size-graph-inner::after {
  background: #ffffff;
  content: '';
  position: absolute;
  top: 40px;
  bottom: 40px;
  right: 40px;
  left: 40px;
  z-index: 3;
  border-radius: inherit;
}
.audience-size-graph-outer {
  position: relative;
  margin: 0px auto -140px;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  clip-path: inset(0px 0px 150px);
}
/* .audience-size-graph-gauge {} */
.audience-size-container p {
  flex-basis: 100%;
  text-align: center;
}
.audience-size-gauge {
  justify-content: space-between;
  padding: 0 10px 20px;
}
.audience-size-number {
  padding: 0 10px;
  font-weight: var(--app-font-bold);
  font-size: 1.1em;
}
.audience-size-insight {
  margin: 20px 0;
  font-weight: var(--app-font-bold);
  font-size: 1.2em;
}
.card__content + .card__content {
  margin-top: 20px;
}
.card__header-step {
  background-color: var(--app-background-color);
}
.campaign-header {
  display: flex;
  line-height: 36px;
  justify-content: space-between;
  padding: 10px 0;
}
/* .wizard-form {
  margin-top: 20px;
} */
.form__field {
  position: relative;
  padding: 0 0 7px;
  margin: 20px 0 0 0;
  border: none;
}
.form__field--only-text {
  padding: 0 20px 7px;
}
.form__field--no-label {
  margin-top: 0;
}
.input {
  border-width: 0;
  /* background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2); */
  /* background-size: 0 2px, 100% 1px; */
  /* background-repeat: no-repeat; */
  /* background-position: center bottom, center calc(100% - 1px); */
  /* background-color: transparent; */
  box-shadow: none;
  /* border-radius: 0; */
  color: var(--app-font-color);
  float: none;
  font-size: var(--app-base-fontsize);
  font-weight: var(--app-font-weight);
  height: 36px;
  line-height: var(--app-line-height);
  margin: 0 0 7px;
  padding: 7px 0;
  /* transition: background 0s ease-out; */
  width: 100%;
  outline: none;
}
.input:disabled {
  color: var(--app-disabled-color);
  cursor: default;
  /* background-color: rgba(235, 235, 228, 0.3); */
}
.input:-internal-autofill-selected {
  /* appearance: menulist-button; */
  /* background-color: #e8f0fe !important; */
  background-color: var(--app-light-blue) !important;
  /* background-image: none !important; */
  /* color: -internal-light-dark(black, white) !important; */
}
.input__text {
  padding: 7px 20px;
}
.input__date {
  padding: 7px 0px 7px 20px;
}
/* .input__date {
  background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2), url('/img/baseline_today_black_48dp.png');
  background-size: 0 2px, 100% 1px, 24px 24px;
  background-repeat: no-repeat;
  background-position: center bottom, center calc(100% - 1px), calc(100% - 3px) 3px;
  background-color: transparent;
}
.input__date--from {
  background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2), url('/img/baseline_today_black_48dp.png');
}
.input__date--to {
  background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2), url('/img/baseline_event_black_48dp.png');
}
.input__text:focus {
  outline: none;
  background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2);
  background-size: 100% 2px, 100% 1px;
  box-shadow: none;
  transition-duration: 0.3s;
} */
.input__label {
  color: var(--app-disabled-color);
  cursor: text;
  font-size: var(--app-base-fontsize);
  font-weight: var(--app-font-weight);
  line-height: var(--input-line-height);
  margin: 10px 0 0;
  position: absolute;
  pointer-events: none;
  transition: 0.3s ease all;
  top: -7px;
  left: 20px;
  will-change: left, top, contents;
}
.input__date ~ .input__label,
.input__text:focus ~ .input__label,
.input__text:valid:not(.input--empty) ~ .input__label,
.input__text:-webkit-autofill ~ .input__label,
.input__text:read-only ~ .input__label,
.input__text:disabled ~ .input__label,
.input__label--focus,
.input__label__multivalue,
.input__label--invalid,
.input__text--invalid,
.input__text:invalid,
.input__text:invalid:not(:-webkit-autofill) ~ .input__label {
  top: -30px;
  left: 20px;
  font-size: 11px;
  line-height: 1;
}
.input__label--invalid,
.input__text--invalid,
.input__date__label--invalid,
.input__text:invalid,
.input__text:invalid:not(:-webkit-autofill) ~ .input__label {
  color: var(--app-dark-red);
}
/* .input__label__multivalue {
  margin: 10px 0 0;
  position: relative;
  display: block;
  top: unset;
  font-size: 11px;
  line-height: 1;
} */
.input__label__singlevalue {
  /* margin: 0 10px 10px 0; */
  margin: 5px 10px 10px 2px;
  border: none;
  border-radius: 4px;
  /* background-color: var(--card-background-color);
  background-image: linear-gradient(black, black), url('/img/baseline_radio_button_unchecked_white_48dp.png');
  background-repeat: no-repeat;
  background-position-x: 3px;
  background-position-y: 3px;
  background-size: 25px;
  background-blend-mode: difference; */
  line-height: var(--input-line-height);
  padding: 0px 10px 0px 25px;
  position: relative;
  display: inline-block;
  top: unset;
  pointer-events: unset;
  cursor: pointer;
  color: black;
}
/* .input__label__singlevalue.input__label--checkbox {
  background-image: linear-gradient(black, black), url('/img/baseline_check_box_outline_blank_white_48dp.png');
} */
.input__radio--checked + .input__label__singlevalue.input__label--radio,
.input__checkbox--checked + .input__label__singlevalue.input__label--checkbox {
  /* background-image: linear-gradient(black, black), url('/img/baseline_check_box_white_48dp.png'); */
  color: var(--app-dark-blue);
}
.input__label__singlevalue:hover {
    outline: none;
    box-shadow: var(--app-shadow-color);
}
.input__radio {
  display: none;
}
/* .input__radio--checked + .input__label__singlevalue,
.input__radio[checked] + .input__label__singlevalue {
    background-image: linear-gradient(black, black), url('/img/baseline_radio_button_checked_white_48dp.png');
} */
.input__radio--disabled + .input__label__singlevalue:hover {
    box-shadow: none;
    cursor: default;
}
.input__select--multiple {
  height: 60px;
  padding: 0;
}
.input__textarea,
.input__select--multiple--large {
  height: 120px;
  margin-top: 5px;
  border-radius: 10px;
  overflow: auto;
  text-indent: 0;
  background-image: none;
}
.tags-list {
  margin-top: 5px;
}
.tags-list--w100 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  height: 125px;
  overflow: auto;
  max-width: 700px;
  width: 700px;
  box-sizing: border-box;
}
.tag {
  max-width: calc(100% - 40px);
  position: relative;
  padding-right: 40px;
}
.tag .btn__img {
  position: absolute;
  right: 10px;
  top: calc(50% - 12px);
  margin: 0;
  padding: 0;
}
.input__fake {
  opacity: 0;
  width: 0;
  height: 0;
}
option {
  padding: 5px 20px;
}
option:checked {
  background-color: var(--app-dark-blue) !important;
  color: var(--app-white) !important;
}
</style>
