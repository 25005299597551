<template>
  <main id="campaign-view">
    <div class="card" id="main-card">
      <div class="campaign-header card__header card__header--with-steps">
        <h4 v-for="(step, index) in steps" :key="index" :class="`card__header-step card__header-step--${step.status}`">
          <span class="step-value">{{ step.value }}</span>
          <span class="step-label">{{ step.label }}</span>
        </h4>
      </div>
      <form action="#create" class="wizard-form" @submit="onSubmit" ref="create-form">
      <div class="card__content">
        <div class="card__header">
          <h4 class="card__title">{{ $t('agency') }} *</h4>
        </div>
        <div class="card__header">
          <fieldset class="form__field form__field--no-label --w75">
            <select class="input input__select" required name="agencyId" id="agencyId" v-model="agencyId">
              <option value="" disabled selected>{{ $t('please-choose') }}</option>
              <option
                v-for="(dataItem, index) in agencies"
                :value="dataItem._id"
                :selected="agencyId === dataItem.value"
                :key="index">{{ dataItem.name }}</option>
            </select>
          </fieldset>
        </div>
      </div>
      <div class="card__content">
        <div class="card__header">
          <h4 class="card__title">{{ $t('advertiser') }} *</h4>
        </div>
        <div class="card__header">
          <fieldset class="form__field form__field--no-label --w75">
            <select class="input input__select" required name="clientId" id="clientId" v-model="clientId">
              <option value="" disabled selected>{{ $t('please-choose') }}</option>
              <option
                v-for="(dataItem, index) in agencyClients"
                :value="dataItem._id"
                :selected="clientId === dataItem.value"
                :key="index">{{ dataItem.name }}</option>
            </select>
          </fieldset>
        </div>
      </div>
      <div class="card__content">
        <div class="card__header">
          <h4 class="card__title">{{ $t('platform') }} *</h4>
        </div>
        <div class="card__header">
          <fieldset class="form__field form__field--no-label --w75">
            <select class="input input__select" required name="platformId" id="platformId" v-model="platformId">
              <option value="" disabled selected>{{ $t('please-choose') }}</option>
              <option disabled
                v-for="(dataItem, index) in availablePlatforms"
                :value="dataItem.value"
                :key="index">{{ dataItem.label }}</option>
            </select>
          </fieldset>
        </div>
      </div>
      <div class="card__content">
        <div class="card__header">
          <h4 class="card__title">{{ $t('account') }} *</h4>
        </div>
        <div class="card__header">
          <fieldset class="form__field form__field--no-label --w75">
            <select class="input input__select" required name="accountId" id="accountId" v-model="accountId">
              <option value="" disabled selected>{{ $t('please-choose') }}</option>
              <option
                v-for="(dataItem, index) in accounts"
                :value="dataItem.api_id"
                :key="index">{{ dataItem.name }}</option>
            </select>
          </fieldset>
        </div>
      </div>
      <div class="card__content">
        <div class="card__header">
          <h4 class="card__title">{{ $t('campaign-name') }} *</h4>
        </div>
        <div class="card__header">
          <fieldset class="form__field form__field--no-label --w100">
            <input type="text" class="input input__text" required name="name" id="name" v-model="name" autocomplete="new-password">
          </fieldset>
        </div>
        <div class="card__header">
          <fieldset class="form__field --w25">
            <input type="date" class="input input__date input__date--from" required name="startDate" id="startDate" v-model="startDate" autocomplete="new-password">
            <label for="startDate" class="input__label">{{ $t('start-date') }} *</label>
          </fieldset>
          <fieldset class="form__field --w25">
            <input type="date" class="input input__date input__date--to" required name="endDate" id="endDate" v-model="endDate" autocomplete="new-password">
            <label for="endDate" class="input__label">{{ $t('end-date') }} *</label>
          </fieldset>
          <fieldset class="form__field --w25">
            <label for="statusId" class="input__label input__label__multivalue">{{ $t('status') }} *</label>
            <select class="input input__select" required name="statusId" id="statusId" v-model="statusId">
              <option
                v-for="(dataItem, index) in availableStatuses"
                :value="dataItem.value"
                :selected="'PAUSED' === dataItem.value"
                :key="index">{{ dataItem.label }}</option>
            </select>
          </fieldset>
          <fieldset class="form__field form__field--no-label --w25"></fieldset>
        </div>
      </div>
      <!-- TAGS: NO MVP -->
      <div class="card__content">
        <div class="card__header">
          <h4 class="card__title">{{ $t('objective') }} *</h4>
        </div>
        <div class="card__header">
          <fieldset class="form__field --w50">
            <select class="input input__select" required name="objectiveId" id="objectiveId" v-model="objectiveId">
              <option value="" disabled selected>{{ $t('please-choose') }}</option>
              <option disabled
                v-for="(dataItem, index) in availableObjectives"
                :value="dataItem.value"
                :selected="objectiveId === dataItem.value"
                :key="index">{{ dataItem.label }}</option>
            </select>
          </fieldset>
          <fieldset class="form__field --w50">
            <label for="specialAdCategoryId" class="input__label input__label__multivalue">{{ $t('special-ad-category') }} *</label>
            <select class="input input__select" required name="specialAdCategoryId" id="specialAdCategoryId" v-model="specialAdCategoryId">
              <option value="" disabled selected>{{ $t('please-choose') }}</option>
              <option
                v-for="(dataItem, index) in specialAdCategories"
                :value="dataItem.value"
                :key="index">{{ $t(`special-ad-category-${dataItem.label}`) }}</option>
            </select>
          </fieldset>
        </div>
      </div>
      <div class="card__content">
        <div class="card__header">
          <h4 class="card__title">{{ $t('campaign-budget-optimization') }}</h4>
        </div>
        <div class="card__header">
          <fieldset class="form__field --w25">
            <label for="budgetPeriod" class="input__label input__label__multivalue">{{ $t('campaign-budget') }} *</label>
            <select class="input input__select" required name="budgetPeriod" id="budgetPeriod" v-model="budgetPeriod">
              <option value="daily" selected>{{ $t('daily-budget') }}</option>
              <option value="lifetime">{{ $t('lifetime-budget') }}</option>
            </select>
            <p class="input__hint">{{ $t('actual-spent-may-vary') }}</p>
          </fieldset>
          <fieldset class="form__field --w25" v-if="budgetPeriod === 'daily'">
            <label for="dailyBudget" class="input__label input__label__multivalue">{{ $t('budget') }} (€) *</label>
            <input type="number" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" maxlength="10" inputmode="decimal" class="input input__text --ta-r" required name="dailyBudget" id="dailyBudget" v-model="dailyBudget" autocomplete="new-password">
          </fieldset>
          <fieldset class="form__field --w25" v-else>
            <label for="lifetimeBudget" class="input__label input__label__multivalue">{{ $t('budget') }} (€) *</label>
            <input type="number" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" maxlength="10" inputmode="decimal" class="input input__text" required name="lifetimeBudget" id="lifetimeBudget" v-model="lifetimeBudget" autocomplete="new-password">
          </fieldset>
          <fieldset class="form__field --w50">&nbsp;</fieldset>
        </div>
        <div class="card__header">
          <fieldset class="form__field form__field--no-label --w100">
            <input class="input__checkbox"
              :class="[ optimizeBudget ? 'input__checkbox--checked' : '' ]"
              type="checkbox"
              v-model="optimizeBudget"
              :checked="optimizeBudget"
              value="true"
              id="optimizeBudget"
              name="optimizeBudget" />
            <label class="input__label input__label--checkbox input__label__singlevalue" for="optimizeBudget">{{ $t('optimize-budget-adsets') }} ({{ optimizeBudget ? $t('activated') : $t('deactivated') }})</label>
          </fieldset>
        </div>
        <div class="card__header" v-if="optimizeBudget">
          <fieldset class="form__field --w25">
            <label for="pacingType" class="input__label input__label__multivalue">{{ $t('pacing-type') }} *</label>
            <select class="input input__select" required name="pacingType" id="pacingType" v-model="pacingType">
              <option v-for="(item, key) in pacingTypes" :value="item.value" :key="key">{{ $t(item.label) }}</option>
            </select>
          </fieldset>
        </div>
        <div class="card__header">
          <fieldset class="form__field --w25">
            <label for="bidStrategyId" class="input__label input__label__multivalue">{{ $t('bid-strategy') }} *</label>
            <select class="input input__select" required name="bidStrategyId" id="bidStrategyId" v-model="bidStrategyId">
              <option value="" disabled selected>{{ $t('please-choose') }}</option>
              <option
                v-for="(dataItem, index) in bidStrategies"
                :value="dataItem.value"
                :key="index">{{ $t(`bid-strategy-${dataItem.label}`) }}</option>
            </select>
          </fieldset>
        </div>
      </div>
      <div class="modal__actions">
        <div class="modal__actions--left">
          <button type="button" class="btn btn--text" @click="onCancel">{{ $t('cancel') }}</button>
        </div>
        <div class="modal__actions--right">
          <button type="button" class="btn btn--new" @click="onSubmit">{{ $t('continue') }}</button>
        </div>
      </div>
      </form>
    </div>
  </main>
</template>
<script>
import {
  GET_USER_DATA,
  GET_AGENCIES,
  GET_CLIENTS,
  GET_ACCOUNTS,
  GET_STATUSES,
  GET_OBJECTIVES,
  GET_PLATFORMS,
  GET_BILLING_EVENTS,
  // GET_OPTIMIZATION_GOALS,
  GET_SPECIAL_AD_CATEGORIES,
  GET_BID_STRATEGIES,
  GET_PACING_TYPES,
  // GET_PAGES_FACEBOOK,
  SAVE_NEW_CAMPAIGN,
  GET_ORDERS,
  GET_CAMPAIGNS,
  HIDE_MODAL_VEIL,
  SHOW_MODAL_VEIL,
  SHOW_MODAL,
  HIDE_MODAL,
  SHOW_NOTIFICATION
} from '@/core/action-types'
import {
  mapGetters,
  mapActions
} from 'vuex'
import i18n from '@/plugins/i18n'
export default {
  name: 'campaign',
  components: {
  },
  data: function () {
    return {
      locale: i18n.locale,
      steps: [
        { value: '1', label: this.$t('campaign'), status: 'active' },
        { value: '2', label: this.$t('ad-sets'), status: 'inactive' },
        { value: '3', label: this.$t('ads'), status: 'inactive' },
        { value: '4', label: this.$t('summary'), status: 'inactive' }
      ],
      agencyId: '',
      clientId: '',
      // DE MOMENTO:
      platformId: 1,
      accountId: '',
      name: '',
      startDate: undefined,
      endDate: undefined,
      statusId: 'PAUSED',
      objectiveId: '',
      bidStrategyId: '',
      dailyBudget: 0.00,
      lifetimeBudget: 0.00,
      specialAdCategoryId: 'NONE',
      adsetCount: 0,
      optimizeBudget: false,
      budgetPeriod: 'daily',
      pacingType: null
    }
  },
  computed: {
    ...mapGetters([
      'authData',
      'agencies',
      'clients',
      'accounts',
      'statuses',
      'objectives',
      'platforms',
      'billingEvents',
      // 'optimizationGoals',
      'specialAdCategories',
      'bidStrategies',
      'pacingTypes',
      // 'pages',
      'filteredAgencies',
      'filteredClients',
      'filteredOrders',
      'filteredLineItems'
    ]),
    agencyClients () {
      return this.agencyId && this.agencyId !== '' ? this.clients.filter(client => client.team_id === this.agencyId) : []
    },
    availableStatuses () {
      return this.statuses.filter(item => item.value === 'ACTIVE' || item.value === 'PAUSED')
    },
    availablePlatforms () {
      // 1: Facebook, 2: Instagram, 3: Google, 4: Twitter, 5: Linkedin
      return this.platforms.filter(item => item.value === 1 || item.value === 4 || item.value === 5)
    },
    availableObjectives () {
      return this.objectives.filter(
        // Solo aparece hasta purchases
        item => item.value === 'LINK_CLICKS' || item.value === 'CONVERSIONS' || item.value === 'REACH' || item.value === 'IMPRESSIONS' || item.value === 'PURCHASES' || item.value === 'LEAD GENERATION' || item.value === 'ENGAGEMENT' || item.value === 'PROFILE LIKES' || item.value === 'MESSAGES SENT' || item.value === 'VIDEO VIEWS' || item.value === 'APP INSTALLS' || item.value === 'THRUPLAY VIEWS')
    }
  },
  mounted () {
    this.fetchUserData()
    this.setUpCampaignData()
    if (this.authData && this.authData.team) {
      this.fetchAgencies({ team_id: this.authData.team._id })
    }
    this.getPlatforms()
    this.fetchAccounts()
    this.getStatuses()
    this.getObjectives()
    this.getBillingEvents()
    // this.getOptimizationGoals()
    this.getSpecialAdCategories()
    this.getBidStrategies()
    this.getPacingTypes()
    // this.getPagesFacebook()
  },
  watch: {
    agencyId (newValue, oldValue) {
      this.fetchClients({ team_id: newValue })
    },
    clientId () {
      this.fetchAccounts()
    },
    platformId () {
      this.fetchAccounts()
    }
  },
  methods: {
    ...mapActions({
      fetchUserData: GET_USER_DATA,
      fetchAgencies: GET_AGENCIES,
      fetchClients: GET_CLIENTS,
      getAccounts: GET_ACCOUNTS,
      getStatuses: GET_STATUSES,
      getObjectives: GET_OBJECTIVES,
      getPlatforms: GET_PLATFORMS,
      getBillingEvents: GET_BILLING_EVENTS,
      // getOptimizationGoals: GET_OPTIMIZATION_GOALS,
      getSpecialAdCategories: GET_SPECIAL_AD_CATEGORIES,
      getBidStrategies: GET_BID_STRATEGIES,
      getPacingTypes: GET_PACING_TYPES,
      // getPagesFacebook: GET_PAGES_FACEBOOK,
      saveNewCampaign: SAVE_NEW_CAMPAIGN,
      fetchOrders: GET_ORDERS,
      fetchCampaigns: GET_CAMPAIGNS,
      showModalVeil: SHOW_MODAL_VEIL,
      hideModalVeil: HIDE_MODAL_VEIL,
      showModal: SHOW_MODAL,
      hideModal: HIDE_MODAL,
      showNotification: SHOW_NOTIFICATION
    }),
    setUpCampaignData () {
      const today = new Date()
      this.agencyId = this.filteredAgencies && this.filteredAgencies.length > 0 ? this.filteredAgencies[0] : ''
      this.clientId = this.filteredClients && this.filteredClients.length > 0 ? this.filteredClients[0] : ''
      // DE MOMENTO:
      this.platformId = 1
      this.accountId = ''
      this.name = ''
      this.startDate = today.toISOString().split('T')[0]
      this.endDate = undefined
      this.statusId = 'PAUSED'
      this.objectiveId = this.$route.params.objective
      this.bidStrategyId = ''
      this.dailyBudget = 0.00
      this.lifetimeBudget = 0.00
      this.specialAdCategoryId = 'NONE'
      this.adsetCount = 0
      this.optimizeBudget = false
      this.budgetPeriod = 'daily'
      this.pacingType = null
    },
    fetchAccounts () {
      let query = { platform: this.platformId }
      if (this.clientId !== undefined) {
        query.team_id = this.clientId
      }
      this.getAccounts(query)
    },
    // EVENTS
    onSubmit () {
      if (this.$refs['create-form'].reportValidity()) {
        let query = {
          platform: this.platformId,
          account_id: this.accountId,
          name: this.name,
          start_date: this.startDate.split('T')[0],
          end_date: this.endDate && this.endDate !== undefined ? this.endDate.split('T')[0] : null,
          status: this.statusId,
          objective: this.objectiveId,
          daily_budget: this.dailyBudget,
          bid_strategy: this.bidStrategyId,
          lifetime_budget: this.lifetimeBudget,
          special_ad_categories: [this.specialAdCategoryId],
          adset_count: 0
        }
        // TODO: PENDIENTE DE @ENEKO PARA QUITAR EL end_date OBLIGATORIO
        // if (query.end_date === null) {
        //   delete query.end_date
        // }
        if (
          this.budgetPeriod !== 'daily' ||
          (query.daily_budget !== undefined && (query.daily_budget === '' || query.daily_budget === '0.0'))) {
          query.daily_budget = null
        }
        if (
          this.budgetPeriod !== 'lifetime' ||
          (query.lifetime_budget !== undefined && (query.lifetime_budget === '' || query.lifetime_budget === '0.0'))) {
          query.lifetime_budget = null
        }
        if (this.optimizeBudget) {
          query.pacing_type = [this.pacingType]
        }
        const saveNewCampaignOnComplete = function (responseData) {
          const newCampaignId = responseData.data._id
          this.showNotification({ text: this.$t('campaign-created'), type: 'success', icon: 'success' })
          // Fetch orders, then campaigns so that Dashboard is up to date
          let queryOrders = { teams: this.filteredClients.join(',') }
          const fetchOrdersOnComplete = function (responseData) {
            let queryCampaigns = { teams: this.filteredClients.join(','), orders: this.filteredOrders.join(','), campaignSets: this.filteredLineItems.join(',') }
            const fetchCampaignsOnComplete = function (responseData) {
              this.$router.push({ path: `/campaign/${newCampaignId}/adset`, query: this.$route.query })
            }
            queryCampaigns.onComplete = fetchCampaignsOnComplete.bind(this)
            this.fetchCampaigns(queryCampaigns)
          }
          queryOrders.onComplete = fetchOrdersOnComplete.bind(this)
          this.fetchOrders(queryOrders)
        }
        query.onComplete = saveNewCampaignOnComplete.bind(this)
        // Actualizar su planning line
        if (this.filteredLineItems && this.filteredLineItems.length === 1) {
          query.lineItemId = this.filteredLineItems[0]
        } else {
          query.lineItemId = undefined
        }
        this.saveNewCampaign(query)
      }
    },
    onCancel () {
      this.$router.push({ name: 'dashboard', params: { tab: 'campaigns' }, query: this.$route.query })
    }
  }
}
</script>
<i18n src="@/core/i18n-common.json"></i18n>
<style scoped>
/* TODO: INTEGRAR ESTILOS CON LOS ESTILOS GENERALES */
#campaign-view {
  margin: 0;
  padding: 0 0 100px;
  display: flex;
  flex-direction: column;
}
#main-card {
  margin-top: 20px;
  width: calc(100% - 40px);
  max-width: 1200px;
}
.card__content + .card__content {
  margin-top: 20px;
}
.card__header-step {
  background-color: var(--app-background-color);
}
.campaign-header {
  display: flex;
  line-height: 36px;
  justify-content: space-between;
  padding: 10px 0;
}
.wizard-form {
  margin-top: 20px;
}
.form__field {
  position: relative;
  padding: 0 0 7px;
  margin: 20px 0 0 0;
  border: none;
}
/* .form__field--multivalue {
  margin: 0;
} */
.form__field--no-label {
  margin-top: 0;
}
.input {
  border-width: 0;
  /* background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2); */
  /* background-size: 0 2px, 100% 1px; */
  /* background-repeat: no-repeat; */
  /* background-position: center bottom, center calc(100% - 1px); */
  /* background-color: transparent; */
  box-shadow: none;
  /* border-radius: 0; */
  color: var(--app-font-color);
  float: none;
  font-size: var(--app-base-fontsize);
  font-weight: var(--app-font-weight);
  height: 36px;
  line-height: var(--app-line-height);
  margin: 0 0 7px;
  padding: 7px 0;
  /* transition: background 0s ease-out; */
  width: 100%;
  outline: none;
}
.input:disabled {
  color: var(--app-disabled-color);
  cursor: default;
  /* background-color: rgba(235, 235, 228, 0.3); */
}
.input:-internal-autofill-selected {
  /* appearance: menulist-button; */
  /* background-color: #e8f0fe !important; */
  background-color: var(--app-light-blue) !important;
  /* background-image: none !important; */
  /* color: -internal-light-dark(black, white) !important; */
}
.input__text {
  padding: 7px 20px;
}
.input__date {
  padding: 7px 0px 7px 20px;
}
/* .input__date {
  background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2), url('/img/baseline_today_black_48dp.png');
  background-size: 0 2px, 100% 1px, 24px 24px;
  background-repeat: no-repeat;
  background-position: center bottom, center calc(100% - 1px), calc(100% - 3px) 3px;
  background-color: transparent;
}
.input__date--from {
  background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2), url('/img/baseline_today_black_48dp.png');
}
.input__date--to {
  background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2), url('/img/baseline_event_black_48dp.png');
}
.input__text:focus {
  outline: none;
  background-image: linear-gradient(#9c27b0, #9c27b0), linear-gradient(#D2D2D2, #D2D2D2);
  background-size: 100% 2px, 100% 1px;
  box-shadow: none;
  transition-duration: 0.3s;
} */
.input__label {
  color: var(--app-disabled-color);
  cursor: text;
  font-size: var(--app-base-fontsize);
  font-weight: var(--app-font-weight);
  line-height: var(--input-line-height);
  margin: 10px 0 0;
  position: absolute;
  pointer-events: none;
  transition: 0.3s ease all;
  top: -7px;
  left: 20px;
  will-change: left, top, contents;
}
.input__date ~ .input__label,
.input__text:focus ~ .input__label,
.input__text:valid:not(.input--empty) ~ .input__label,
.input__text:-webkit-autofill ~ .input__label,
.input__text:read-only ~ .input__label,
.input__text:disabled ~ .input__label,
.input__label--focus,
.input__label__multivalue,
.input__label--invalid,
.input__text--invalid,
.input__text:invalid,
.input__text:invalid:not(:-webkit-autofill) ~ .input__label {
  top: -30px;
  left: 20px;
  font-size: 11px;
  line-height: 1;
}
.input__label--invalid,
.input__text--invalid,
.input__date__label--invalid,
.input__text:invalid,
.input__text:invalid:not(:-webkit-autofill) ~ .input__label {
  color: var(--app-dark-red);
}
/* .input__label__multivalue {
  margin: 10px 0 0;
  position: relative;
  display: block;
  top: unset;
  font-size: 11px;
  line-height: 1;
} */
.input__label__singlevalue {
  /* margin: 0 10px 10px 0; */
  margin: 5px 10px 10px 2px;
  border: none;
  border-radius: 4px;
  /* background-color: var(--card-background-color);
  background-image: linear-gradient(black, black), url('/img/baseline_radio_button_unchecked_white_48dp.png');
  background-repeat: no-repeat;
  background-position-x: 3px;
  background-position-y: 3px;
  background-size: 25px;
  background-blend-mode: difference; */
  line-height: var(--input-line-height);
  padding: 0px 10px 0px 25px;
  position: relative;
  display: inline-block;
  top: unset;
  pointer-events: unset;
  cursor: pointer;
  color: black;
}
/* .input__label__singlevalue.input__label--checkbox {
  background-image: linear-gradient(black, black), url('/img/baseline_check_box_outline_blank_white_48dp.png');
} */
.input__radio--checked + .input__label__singlevalue.input__label--radio,
.input__checkbox--checked + .input__label__singlevalue.input__label--checkbox {
  /* background-image: linear-gradient(black, black), url('/img/baseline_check_box_white_48dp.png'); */
  color: var(--app-dark-blue);
}
.input__label__singlevalue:hover {
    outline: none;
    box-shadow: var(--app-shadow-color);
}
.input__radio {
  display: none;
}
/* .input__radio--checked + .input__label__singlevalue,
.input__radio[checked] + .input__label__singlevalue {
    background-image: linear-gradient(black, black), url('/img/baseline_radio_button_checked_white_48dp.png');
} */
.input__radio--disabled + .input__label__singlevalue:hover {
    box-shadow: none;
    cursor: default;
}
.input__select--multiple {
  height: 60px;
  padding: 0;
}
.input__textarea,
.input__select--multiple--large {
  height: 120px;
  margin-top: 5px;
  border-radius: 10px;
  overflow: auto;
  text-indent: 0;
  background-image: none;
}
.tags-list {
  margin-top: 5px;
}
.tags-list--w100 {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  height: 125px;
  overflow: auto;
  max-width: 700px;
  width: 700px;
  box-sizing: border-box;
}
.tag {
  max-width: calc(100% - 40px);
  position: relative;
  padding-right: 40px;
}
.tag .btn__img {
  position: absolute;
  right: 10px;
  top: calc(50% - 12px);
  margin: 0;
  padding: 0;
}
.input__fake {
  opacity: 0;
  width: 0;
  height: 0;
}
option {
  padding: 5px 20px;
}
option:checked {
  background-color: var(--app-dark-blue) !important;
  color: var(--app-white) !important;
}
</style>
